var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200","fullscreen":_vm.$vuetify.breakpoint.mobile,"scrollable":""},model:{value:(_vm.dialog.dialog),callback:function ($$v) {_vm.$set(_vm.dialog, "dialog", $$v)},expression:"dialog.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"p-0",style:({ display: 'block' })},[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'p-2 pb-0' : 'p-4 pb-0'},[_c('v-toolbar',{staticClass:"header",attrs:{"dark":""}},[_c('v-toolbar-title',{staticClass:"pt-4"},[_c('p',{staticClass:"text-center"},[_vm._v(" Recetas dispensadas ")])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)]),_c('v-card-text',[_c('v-container',[_c('div',{class:_vm.$vuetify.breakpoint.mobile
              ? 'card-body pt-5 p-0'
              : 'card-body p-3'},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0"},[_c('p',{staticClass:"text--primary"},[_c('i',{staticClass:"feather icon-tag mr-2 text-fmedical-blue"}),_c('strong',[_c('span',[_vm._v("Clave CNIS: ")])]),_vm._v(" "+_vm._s(_vm.cluesKey)+" ")])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('p',{staticClass:"text--primary"},[_c('i',{staticClass:"feather icon-package mr-2 text-fmedical-blue"}),_c('strong',[_c('span',[_vm._v(" Producto: ")])]),_vm._v(" "+_vm._s(_vm.nameProduct)+" ")])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dispensingData,"search":_vm.search,"fixed-header":"","height":"auto","footer-props":{
                  'items-per-page-text': _vm.$t('table.item_table'),
                  'items-per-page-all-text': _vm.$t('table.all'),
                  'items-per-page-options': [-1],
                },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":"","header-props":{ sortByText: 'Ordenar por' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-4 col-xl-4 pb-0"},[_c('v-text-field',{staticClass:"br",attrs:{"outlined":"","dense":"","append-icon":"feather icon-search","label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.supplyPercentage",fn:function(ref){
                var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-progress-linear',{staticClass:"br",attrs:{"color":_vm.utilFront.colorPercentajeTable(
                        parseInt(item.supplyPercentage.replace('%', ''))
                      ),"height":"18","value":parseInt(item.supplyPercentage.replace('%', '')),"rounded":"","dark":""}},[(
                        parseInt(item.supplyPercentage.replace('%', '')) == 0
                      )?_c('strong',[_vm._v("-")]):(
                        parseInt(item.supplyPercentage.replace('%', '')) > 100
                      )?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil( parseInt(item.supplyPercentage.replace("%", "")) ))+"%")])]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',[(
                        parseInt(item.supplyPercentage.replace('%', '')) == 0
                      )?_c('strong',[_vm._v("-")]):(
                        parseInt(item.supplyPercentage.replace('%', '')) > 100
                      )?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil( parseInt(item.supplyPercentage.replace("%", "")) ))+"%")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.onChangeTableItem(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-file-text mr-2"})])]}}],null,true)},[_c('span',[_vm._v("PDF")])])]}}],null,true)})],1)])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }