<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card">
        <div class="card-body pb-0">
          <h4 class="text-right" >CENTROS DE SALUD</h4>
          <div class="pl-3">
            <h5 class="mt-0 mb-1 font-16">
              {{ modelJurisdiction.maxPercentage.name }}
            </h5>
            <p class="mb-2">
              <i class="feather icon-trending-up mr-2"></i>Centro de Salud con Mayor Abasto
            </p>

            <v-progress-linear
              v-bind:color="
                utilFront.colorPercentajeBar(
                  modelJurisdiction.maxPercentage.percentageSupplier
                )
              "
              height="24"
              class="br mb-5"
              :value="modelJurisdiction.maxPercentage.percentageSupplier"
              rounded
              dark
            >
              <strong
                >{{
                  Math.ceil(modelJurisdiction.maxPercentage.percentageSupplier)
                }}%</strong
              >
            </v-progress-linear>

            <h5 class="mt-0 mb-1 font-16">
              {{ modelJurisdiction.minPercentage.name }}
            </h5>
            <p class="mb-2">
              <i class="feather icon-trending-down mr-1"></i>Centro de Salud con Menor Abasto
            </p>

            <v-progress-linear
              v-bind:color="
                utilFront.colorPercentajeBar(
                  modelJurisdiction.minPercentage.percentageSupplier
                )
              "
              height="24"
              class="br mb-5"
              :value="modelJurisdiction.minPercentage.percentageSupplier"
              rounded
              dark
            >
              <strong
                >{{
                  Math.ceil(modelJurisdiction.minPercentage.percentageSupplier)
                }}%</strong
              >
            </v-progress-linear>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card ">
        <div class="card-body pb-0">
          <h4 class="text-right">HOSPITALES</h4>
          <div class="pl-3">
            <h5 class="mt-0 mb-1 font-16">
              {{ modelJurisdiction.maxPercentageHospital.name }}
            </h5>
            <p class="mb-2">
              <i class="feather icon-trending-up mr-2"></i>Hospital con Mayor Abasto
            </p>

            <v-progress-linear
              v-bind:color="
                utilFront.colorPercentajeBar(
                  modelJurisdiction.maxPercentageHospital.percentageSupplier
                )
              "
              height="24"
              class="br mb-5"
              :value="
                modelJurisdiction.maxPercentageHospital.percentageSupplier
              "
              rounded
              dark
            >
              <strong
                >{{
                  Math.ceil(
                    modelJurisdiction.maxPercentageHospital.percentageSupplier
                  )
                }}%</strong
              >
            </v-progress-linear>

            <h5 class="mt-0 mb-1 font-16">
              {{ modelJurisdiction.minPercentageHospital.name }}
            </h5>
            <p class="mb-2">
              <i class="feather icon-trending-down mr-2"></i>Hospital con Menor Abasto
            </p>

            <v-progress-linear
              v-bind:color="
                utilFront.colorPercentajeBar(
                  modelJurisdiction.minPercentageHospital.percentageSupplier
                )
              "
              height="24"
              class="br mb-5"
              :value="
                modelJurisdiction.minPercentageHospital.percentageSupplier
              "
              rounded
              dark
            >
              <strong
                >{{
                  Math.ceil(
                    modelJurisdiction.minPercentageHospital.percentageSupplier
                  )
                }}%</strong
              >
            </v-progress-linear>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UtilFront } from "@/other/utils";

export default {
  props: {
    modelJurisdiction: {
      default: {},
    },
  },
  data() {
    return {
      chart: {},
      utilFront: UtilFront,
      doughut: [],
    };
  },
};
</script>
<style scoped>
canvas {
  margin: auto;
  width: 150px !important;
  height: 75px !important;
}
</style>
<style scoped src="@/other/assets/css/app.css"></style>
