<template>
  <div class="row align-items-center">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card text-center">
        <div class="card-header border">
          <h4 class="card-title mb-0">
            <i class="feather icon-percent mr-2"></i>{{ title }}
          </h4>
        </div>
        <div class="card-body">
          <div>
            <canvas :id="id"></canvas>
          </div>
          <p class="pt-3">{{ titleCard() }}</p>
          <div class="progress" style="height: 6px;">
            <div
              class="progress-bar"
              role="progressbar"
              v-bind:style="{
                width: percentage + '%',
                backgroundColor: utilFront.colorPercentaje(percentage),
              }"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
import { UtilFront } from "@/other/utils";

export default {
  props: {
    id: {
      type: String,
      default: "jalisco-map",
    },
    percentage: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    generalAverage: Object,
  },
  data() {
    return {
      chart: {},
      utilFront: UtilFront,
    };
  },
  mounted() {
    //Despliego el los porcentajes de de las jurisdiciones
    const ctxDougHut = document.getElementById(this.id);
    this.chart = new Chart(ctxDougHut, this.doughut);
  },
  methods: {
    titleCard() {
      let title = "";
      switch (this.id) {
        case "general_jurisdiction":
          title = "Abasto";
          break;
        case "max_percentaje_jurisdiction":
          title = "Mayor Abasto";
          break;
        case "min_percentaje_jurisdiction":
          title = "Menor Abasto";
          break;
      }
      return title;
    },
    refreshSupplyChart() {
      this.createdChart(true);
    },
    createdChart(val) {
      let percentage = 0;
      if (!val) {
        percentage = this.percentage;
      } else {
        this.chart.destroy();
        percentage =
          this.id == "percentaje_key"
            ? this.generalAverage.percentageKey
            : this.generalAverage.percentageSupply;
      }
      this.doughut = {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: percentage >= 100 ? [100] : [percentage, 100 - percentage],
              backgroundColor: UtilFront.colorPercentaje(percentage),
            },
          ],
        },
        plugins: [
          {
            id: "customPlugin",
            beforeDraw: function(chart) {
              var width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;

              ctx.restore();
              var fontSize = (height / 100).toFixed(2);
              ctx.font = fontSize + "em Roboto-Regular";
              ctx.textBaseline = "middle";

              var text = percentage >= 100 ? 100 + "%" : percentage + "%",
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 1.9;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ],
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          responsive: true,
          cutoutPercentage: 60,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      };
      if (val) {
        const ctxDougHut = document.getElementById(this.id);
        this.chart = new Chart(ctxDougHut, this.doughut);
      }
    },
  },
  created() {
    this.createdChart(false);
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
