<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="row">
        <div class="col-md-12 p-3 pt-0">
          <iframe
            width="100%"
            height="800"
            :src="url.value"
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { UtilFront } from "@/core";

export default {
  computed: {
    // Extración del state
    ...mapState("global", {
      url: "urlStaticDashboard",
    }),
  },
  methods: {
    ...mapActions({
      getDashboardStatisticUrl: "global/getDashboardStatisticUrl",
    }),
  },
  created() {
    let payload = {
      user: {
        idUser: UtilFront.getDataUser().idUser,
      },
      dashboardTypeId: 2,
    };
    this.getDashboardStatisticUrl(payload);
  },
};
</script>
