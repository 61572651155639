<template>
  <transition name="fade">
    <div v-if="loadingPage" id="containerbar">
      <div
        id="containerbar"
        v-bind:class="$vuetify.breakpoint.mdAndDown ? 'pb' : ''"
      >
        <LoadingCommon />

        <div class="rightbar">
          <!-- skeleton-loader -->
          <div v-if="tabs == null && isLoading">
            <SkeletonCommon />
          </div>
          <div
            v-if="tabs == null && !isLoading"
            class="breadcrumbbar text-center"
          >
            <div v-if="isLoading">
              <Error class="pb-3" />
              <h1 id="noinfo">Tablero de control sin parámetros.</h1>
            </div>
          </div>
          <!-- end skeleton-loader -->
          <div v-show="tabs == 0 || tabs != null" class="breadcrumbbar">
            <div class="row p-2 align-items-center">
              <div
                v-if="!$vuetify.breakpoint.mdAndDown"
                class="col-sm-12 col-md-12 col-xl-9 pb-0 pt-0"
              >
                <v-tabs
                  :value="tabs"
                  :color="modelUser.levelDashboard == 3 ? '#fff' : '#4c8c6c'"
                >
                  <v-tab @change="setTap(0)" v-show="NIVEL_ABASTO">
                    <h5><i class="feather icon-bar-chart-2 mr-2"></i>ABASTO</h5>
                  </v-tab>
                  <v-tab
                    @change="setTap(1)"
                    v-show="
                      modelJurisdiction.currentJurisdiction != '' &&
                      NIVEL_JURISDICTION
                    "
                    class="align-center"
                  >
                    <h5>
                      <i class="feather icon-map-pin mr-2"></i
                      >{{ modelJurisdiction.currentJurisdiction }}
                    </h5>
                  </v-tab>
                  <v-tab
                    @change="setTap(2)"
                    v-show="
                      modelBranchOffice.nameBranchOffice != '' && NIVEL_BRANCH
                    "
                  >
                    <h5 class="text">
                      <i class="feather icon-package mr-2"></i>
                      {{ modelBranchOffice.nameBranchOffice }}
                    </h5>
                  </v-tab>
                </v-tabs>
              </div>
              <div class="col-sm-12 col-md-12 col-xl-3">
                <v-select
                  :items="typeDashboard"
                  label="Cuadro de distribución"
                  dense
                  hide-details
                  @change="getInformationDashBord()"
                  v-model="clave"
                  outlined
                >
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="initTutorial()"
                          v-bind="attrs"
                          v-on="on"
                        >
                          feather icon-alert-circle
                        </v-icon>
                      </template>
                      {{ nameDistrubution }}
                    </v-tooltip>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="contentbar">
            <div class="row">
              <div class="col-lg-12 col-xl-12 col-md-12">
                <v-tabs-items touchless id="custom-tab-items" :value="tabs">
                  <v-tab-item>
                    <AbastoComponents v-if="NIVEL_ABASTO" />
                  </v-tab-item>
                  <v-tab-item>
                    <JurisdictionComponent v-if="NIVEL_JURISDICTION" />
                  </v-tab-item>
                  <v-tab-item>
                    <BranchOfficeComponent v-if="NIVEL_BRANCH" />
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
            <div v-show="$vuetify.breakpoint.mdAndDown" class="footerbar">
              <v-bottom-navigation
                color="primary"
                height="65px"
                shift
                app
                :value="tabs"
              >
                <v-btn
                  @change="setTap(0)"
                  v-show="NIVEL_ABASTO"
                  color="deep accent-4"
                  width="100%"
                >
                  <span>Abastos</span>
                  <v-icon>feather icon-bar-chart-2</v-icon>
                </v-btn>

                <v-btn
                  v-show="
                    modelJurisdiction.currentJurisdiction != '' &&
                    NIVEL_JURISDICTION
                  "
                  @change="setTap(1)"
                  width="100%"
                  color="deep accent-4"
                >
                  <span>Jurisdicción</span>
                  <v-icon>feather icon-map-pin</v-icon>
                </v-btn>

                <v-btn
                  v-show="
                    modelBranchOffice.nameBranchOffice != '' && NIVEL_BRANCH
                  "
                  @change="setTap(2)"
                  width="100%"
                  color="deep accent-4"
                >
                  <span>Sucursal</span>
                  <v-icon>feather icon-package</v-icon>
                </v-btn>
              </v-bottom-navigation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AbastoComponents from "@/other/components/jalisco/AbastoComponents";
import JurisdictionComponent from "@/other/components/jalisco/JurisdictionComponent";
import BranchOfficeComponent from "@/other/components/jalisco/BranchOfficeComponent";
import LoadingCommon from "@/other/common/LoadingCommon";
import SkeletonCommon from "@/other/common/SkeletonCommon";
import Error from "@/other/common/Error";
import moment from "moment";
import { UtilFront, PermissionConstants } from "@/core";
import { SwalInfo } from "@/other/utils/SwalAlert.js";
/* Vuex */
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      loadingPage: false,
      clave: "DNB-1",
      permissions: PermissionConstants,
      /* Nivel User Dasboard */
      NIVEL_ABASTO: false,
      NIVEL_JURISDICTION: false,
      NIVEL_BRANCH: false,
    };
  },
  computed: {
    // Extración del state
    ...mapState("global", {
      tabs: "tabs",
      isLoading: "isLoading",
      idDistributionBoard: "idDistributionBoard",
      typeDashboard: "typeDashboard",
      modelUser: "modelUser",
      branchSelection: "branchSelection",
    }),
    ...mapState("jurisdiction", {
      modelJurisdiction: "modelJurisdiction",
    }),
    ...mapState("branchOffice", {
      modelBranchOffice: "modelBranchOffice",
    }),
    nameDistrubution() {
      const filteredElements = this.typeDashboard.filter(
        (element) => element.value == this.clave
      );
      if (filteredElements.length > 0) {
        return filteredElements[0].text;
      } else {
        return ""; 
      }
    },
  },
  methods: {
    initTutorial() {
      SwalInfo.fire({
        icon: "",
        title: "",
        confirmButtonText:
          '<i class="feather icon-bar-chart-2 mr-1"></i> Aceptar',
        html: "<div pb-0> <h3>Tablero de Control</h3> <p>El cambio de cuadro de distribución modifica los productos que se toman para realizar los cálculos de porcentaje de abasto por sucursal.</p> </div>",
      });
      /*  this.$intro()
        .setOptions({
          nextLabel: "Siguiente",
          prevLabel: "Anterior",
          doneLabel: "Finalizar",
          showBullets: false,
          skipLabel: "Omitir",
          showStepNumbers: false,
          tooltipClass: "p-4 tour-js",
          stepNumbersOfLabel: true,
          scrollTo: true,
          scrollPadding: true,
          overlayOpacity: 0.7,
          buttonClass: "btn btn-outline-success",
          steps: [
            {
              element: ".abasto_abasto",
              intro:
                "Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño.",
              position: "bottom",
            },
            {
              tooltipPosition: "bottom",
              element: document.querySelector(".abasto_abasto_secod"),
              intro:
                "Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño.",
            },
            {
              element: ".map_map",
              intro:
                "Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño.",
              position: "bottom",
            },

            {
              element: ".map_map_second",
              intro:
                "Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño.",
              position: "bottom",
            },
          ],
        })
        .start(); */
    },
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    ...mapMutations("global", {
      setTap: "TAP_SHOW",
    }),
    ...mapMutations("global", {
      setDistrubution: "IS_DISTRIBUTION",
    }),
    ...mapActions({
      dashboardNivelUserAction: "global/dashboardNivelUserAction",
      /* JURISDICTION ACTION */
      dashboardDetailJurisdictionAction:
        "jurisdiction/dashboardDetailJurisdictionAction",
      dashboardJurisdictionChartAction:
        "jurisdiction/dashboardJurisdictionChartAction",
      /* BRANCH_OFFICE ACTION */
      dashboardBranchOficceDetailAction:
        "branchOffice/dashboardBranchOficceDetailAction",
      dashboardBranchDetailAction: "branchOffice/dashboardBranchDetailAction",
      /* SUPPLY ACTION */
      dashboardDetail: "supply/dashboardDetailAction",
      dashboardDetailChartAction: "supply/dashboardDetailChartAction",
    }),

    async getInformationDashBord() {
      if (this.NIVEL_ABASTO) {
        this.setDistrubution(this.clave);
        await this.dashboardDetail({
          dateDashboard: moment().format("YYYY-MM-DD"),
        });
        await this.dashboardDetailChartAction({
          distributionBoardId: this.idDistributionBoard,
        });
        await this.dashboardDetailJurisdictionAction(null);
        await this.dashboardBranchOficceDetailAction(null);
      } else if (this.NIVEL_JURISDICTION && this.NIVEL_BRANCH) {
        let payloadDetails = {
          dateDashboard: moment().format("YYYY-MM-DD"),
          jurisdictionId: this.modelUser.jurisdictionId,
        };
        let payloadTables = {
          jurisdictionId: this.modelUser.jurisdictionId,
          distributionBoardId: this.clave,
          jurisdictionName: this.modelUser.jurisdictionName,
        };
        await this.dashboardDetailJurisdictionAction(payloadDetails);
        await this.dashboardJurisdictionChartAction(payloadTables);
        await this.dashboardBranchOficceDetailAction(null);
      } else if (this.NIVEL_BRANCH) {
        let payloadJurisdiction = {
          branchOfficeId:
            this.modelUser.userListBranchOffice[this.branchSelection]
              .idBranchOffice,
          distributionBoardId: this.clave,
          nameBranchOffice:
            this.modelUser.userListBranchOffice[this.branchSelection],
          selectBranches: this.branchSelection,
        };
        let payloadDetail = {
          jurisdictionId:
            this.modelUser.userListBranchOffice[this.branchSelection]
              .jurisdiction,
          branchOfficeId:
            this.modelUser.userListBranchOffice[this.branchSelection]
              .idBranchOffice,
        };
        await this.dashboardBranchOficceDetailAction(payloadJurisdiction);
        await this.dashboardBranchDetailAction(payloadDetail);
      }
    },
  },
  async created() {
    setTimeout(() => (this.loadingPage = !this.loadingPage), 10);
    this.loadingPage = false;
    await this.dashboardNivelUserAction({
      user: { idUser: UtilFront.getDataUser().idUser },
    });
    this.clave = this.idDistributionBoard;
    switch (this.modelUser.levelDashboard) {
      case 1:
        this.NIVEL_ABASTO = true;
        this.NIVEL_JURISDICTION = true;
        this.NIVEL_BRANCH = true;
        await this.getInformationDashBord();
        break;
      case 2:
        this.NIVEL_ABASTO = false;
        this.NIVEL_JURISDICTION = true;
        this.NIVEL_BRANCH = true;
        this.setDistrubution(this.modelUser.distributionBoardId);
        this.clave = this.idDistributionBoard;
        await this.getInformationDashBord();
        break;
      case 3:
        this.NIVEL_ABASTO = false;
        this.NIVEL_JURISDICTION = false;
        this.NIVEL_BRANCH = true;
        this.setDistrubution(this.modelUser.distributionBoardId);
        this.clave = this.idDistributionBoard;
        await this.getInformationDashBord();
        break;
      default:
        break;
    }
  },
  components: {
    AbastoComponents,
    LoadingCommon,
    JurisdictionComponent,
    BranchOfficeComponent,
    SkeletonCommon,
    Error,
  },
  watch: {
    isLoading() {
      window.scrollTo({
        top: 0,
        left: 100,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style src="@/other/assets/css/tour.css"></style>

<style scoped src="@/other/assets/css/app.css"></style>
<style src="@/other/assets/css/loading.css"></style>
<style scoped>
#custom-tab-items {
  background-color: transparent !important;
}
.v-tab {
  max-width: 800px !important;
}
.v-tabs-bar__content {
  flex-wrap: wrap;
  width: 100%;
}
.v-tabs-bar {
  height: auto;
}
.v-tab {
  line-height: 3rem; /* or use padding instead */
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.pb {
  padding-bottom: 60px;
}
</style>

<style scoped lang="scss">
::v-deep {
  div {
    font-family: "Roboto-Regular" !important;
  }
}
</style>
