<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <v-data-table
        :headers="headers"
        :items="dataList"
        :search="search"
        class="p-4 br"
        fixed-header
        height="auto"
        :footer-props="{
          'items-per-page-text': $t('table.item_table'),
          'items-per-page-all-text': $t('table.all'),
          'items-per-page-options': [-1],
        }"
        :header-props="{ sortByText: 'Ordenar por' }"
        :loading-text="$t('table.loading')"
        :no-data-text="$t('table.no_results_available')"
        :no-results-text="$t('table.no_results_search')"
        dense
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 pb-0">
              <v-text-field
                outlined
                dense
                class="br"
                append-icon="feather icon-search"
                v-model="search"
                label="Buscar..."
              ></v-text-field>
            </div>
          </div>
        </template>
        <template v-slot:[`item.quantityS`]="{ item }">
          {{ numeral(item.quantityS).format("0,0") }}
        </template>
        <template v-slot:[`item.quantityD`]="{ item }">
          {{ numeral(item.quantityD).format("0,0") }}
        </template>

        <template slot="body.append">
          <tr>
            <th>Total</th>
            <th></th>
            <th class="text-center">
              {{ numeral(getSumProductsDispensing).format("0,0") }}
            </th>
            <th class="text-center">
              {{ numeral(getSumMedicalPrescription).format("0,0") }}
            </th>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { UtilFront } from "@/other/utils";
import { DISPENCING } from "@/other/utils/DataTableHeaders";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      numeral: require("numeral"),
      search: "",
      utilFront: UtilFront,
      headers: DISPENCING(),
      sumProductsDispensing: 0,
      sumMedicalPrescription: 0,
    };
  },
  computed: {
    // Extración del state
    ...mapGetters("supply", {
      getSumProductsDispensing: "getSumProductsDispensing",
      getSumMedicalPrescription: "getSumMedicalPrescription",
    }),
    ...mapState("supply", {
      dataList: "dataList",
    }),
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
