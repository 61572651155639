var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[(_vm.loadingPage)?_c('div',{attrs:{"id":"containerbar"}},[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'pb' : '',attrs:{"id":"containerbar"}},[_c('LoadingCommon'),_c('div',{staticClass:"rightbar"},[(_vm.tabs == null && _vm.isLoading)?_c('div',[_c('SkeletonCommon')],1):_vm._e(),(_vm.tabs == null && !_vm.isLoading)?_c('div',{staticClass:"breadcrumbbar text-center"},[(_vm.isLoading)?_c('div',[_c('Error',{staticClass:"pb-3"}),_c('h1',{attrs:{"id":"noinfo"}},[_vm._v("Tablero de control sin parámetros.")])],1):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabs == 0 || _vm.tabs != null),expression:"tabs == 0 || tabs != null"}],staticClass:"breadcrumbbar"},[_c('div',{staticClass:"row p-2 align-items-center"},[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('div',{staticClass:"col-sm-12 col-md-12 col-xl-9 pb-0 pt-0"},[_c('v-tabs',{attrs:{"value":_vm.tabs,"color":_vm.modelUser.levelDashboard == 3 ? '#fff' : '#4c8c6c'}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.NIVEL_ABASTO),expression:"NIVEL_ABASTO"}],on:{"change":function($event){return _vm.setTap(0)}}},[_c('h5',[_c('i',{staticClass:"feather icon-bar-chart-2 mr-2"}),_vm._v("ABASTO")])]),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.modelJurisdiction.currentJurisdiction != '' &&
                    _vm.NIVEL_JURISDICTION
                  ),expression:"\n                    modelJurisdiction.currentJurisdiction != '' &&\n                    NIVEL_JURISDICTION\n                  "}],staticClass:"align-center",on:{"change":function($event){return _vm.setTap(1)}}},[_c('h5',[_c('i',{staticClass:"feather icon-map-pin mr-2"}),_vm._v(_vm._s(_vm.modelJurisdiction.currentJurisdiction)+" ")])]),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.modelBranchOffice.nameBranchOffice != '' && _vm.NIVEL_BRANCH
                  ),expression:"\n                    modelBranchOffice.nameBranchOffice != '' && NIVEL_BRANCH\n                  "}],on:{"change":function($event){return _vm.setTap(2)}}},[_c('h5',{staticClass:"text"},[_c('i',{staticClass:"feather icon-package mr-2"}),_vm._v(" "+_vm._s(_vm.modelBranchOffice.nameBranchOffice)+" ")])])],1)],1):_vm._e(),_c('div',{staticClass:"col-sm-12 col-md-12 col-xl-3"},[_c('v-select',{attrs:{"items":_vm.typeDashboard,"label":"Cuadro de distribución","dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.getInformationDashBord()}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.initTutorial()}}},'v-icon',attrs,false),on),[_vm._v(" feather icon-alert-circle ")])]}}],null,false,1553616472)},[_vm._v(" "+_vm._s(_vm.nameDistrubution)+" ")])]},proxy:true}],null,false,918806882),model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=$$v},expression:"clave"}})],1)])]),_c('div',{staticClass:"contentbar"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12 col-md-12"},[_c('v-tabs-items',{attrs:{"touchless":"","id":"custom-tab-items","value":_vm.tabs}},[_c('v-tab-item',[(_vm.NIVEL_ABASTO)?_c('AbastoComponents'):_vm._e()],1),_c('v-tab-item',[(_vm.NIVEL_JURISDICTION)?_c('JurisdictionComponent'):_vm._e()],1),_c('v-tab-item',[(_vm.NIVEL_BRANCH)?_c('BranchOfficeComponent'):_vm._e()],1)],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndDown),expression:"$vuetify.breakpoint.mdAndDown"}],staticClass:"footerbar"},[_c('v-bottom-navigation',{attrs:{"color":"primary","height":"65px","shift":"","app":"","value":_vm.tabs}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.NIVEL_ABASTO),expression:"NIVEL_ABASTO"}],attrs:{"color":"deep accent-4","width":"100%"},on:{"change":function($event){return _vm.setTap(0)}}},[_c('span',[_vm._v("Abastos")]),_c('v-icon',[_vm._v("feather icon-bar-chart-2")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.modelJurisdiction.currentJurisdiction != '' &&
                  _vm.NIVEL_JURISDICTION
                ),expression:"\n                  modelJurisdiction.currentJurisdiction != '' &&\n                  NIVEL_JURISDICTION\n                "}],attrs:{"width":"100%","color":"deep accent-4"},on:{"change":function($event){return _vm.setTap(1)}}},[_c('span',[_vm._v("Jurisdicción")]),_c('v-icon',[_vm._v("feather icon-map-pin")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.modelBranchOffice.nameBranchOffice != '' && _vm.NIVEL_BRANCH
                ),expression:"\n                  modelBranchOffice.nameBranchOffice != '' && NIVEL_BRANCH\n                "}],attrs:{"width":"100%","color":"deep accent-4"},on:{"change":function($event){return _vm.setTap(2)}}},[_c('span',[_vm._v("Sucursal")]),_c('v-icon',[_vm._v("feather icon-package")])],1)],1)],1)])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }