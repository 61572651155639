var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[(
          _vm.modelUser.userListBranchOffice &&
            _vm.modelUser.userListBranchOffice.length > 1
        )?_c('div',{staticClass:"card-header p-0 ml-2 mt-1"},[_c('b-tooltip',{attrs:{"target":"messagues"}},[_c('span',{staticClass:"letter"},[_vm._v("Este usuario cuenta con más de una sucursal asignada, para realizar un cambio de sucursal da click en el icono "),_c('i',{staticClass:"feather icon-chevron-down"}),_vm._v(" o en el nombre de la sucursal .")])]),_c('i',{staticClass:"hand feather icon-info info-color",attrs:{"id":"messagues"}})],1):_vm._e(),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body text-center"},[_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-10 col-xl-10 p-0"},[(
                    _vm.modelUser.userListBranchOffice &&
                      _vm.modelUser.userListBranchOffice.length > 1
                  )?[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var attrs = ref.attrs;
                  var on = ref.on;
return [_c('h2',_vm._g(_vm._b({staticClass:"title-color"},'h2',attrs,false),on),[_vm._v(" "+_vm._s(_vm.modelBranchOffice.nameBranchOffice)+" "),_c('i',{staticClass:"feather icon-chevron-down"})])]}}],null,false,3368639117)},[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.modelUser.userListBranchOffice),function(item){return _c('v-list-item',{key:item.idBranchOffice},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)]:_c('h2',[_vm._v(_vm._s(_vm.modelBranchOffice.nameBranchOffice))])],2)]),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(_vm.modelBranchOffice.zone != ''),expression:"modelBranchOffice.zone != ''"}],staticClass:"card-title font-18 pt-1"},[_vm._v(" "+_vm._s(_vm.modelBranchOffice.zone)+" | "+_vm._s(_vm.modelBranchOffice.clues)+" ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }