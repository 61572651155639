<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card text-center m-b-20">
        <div class="card-body pt-3 pb-2">
          <div class="row">
            <div class="col-sm-12 text-center pb-0">
              <h4 class="card-title">{{ complement()[1] }}</h4>
              <h1>
                <span
                  :style="{
                    borderRadius: '10px',
                    color: '#fff',
                    backgroundColor:
                      id == 'general'
                        ? utilFront.colorPercentaje(percentaje)
                        : utilFront.colorPercentaje(percentaje.percentage),
                  }"
                  class="badge"
                  >{{
                    id == "general"
                      ? percentaje + " %"
                      : percentaje.percentage + " %"
                  }}</span
                >
              </h1>
            </div>
          </div>
          <div class="col-sm-12 text-center">
            <h6 class="pb-0 pt-4">
              <i :class="complement()[0]"></i> {{ complement()[2] }}
            </h6>
          </div>
          <div class="progress" style="height: 7px;">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{
                width:
                  id == 'general'
                    ? percentaje + '%'
                    : percentaje.percentage + '%',
                backgroundColor:
                  id == 'general'
                    ? utilFront.colorPercentaje(percentaje)
                    : utilFront.colorPercentaje(percentaje.percentage),
              }"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UtilFront } from "@/other/utils";

export default {
  props: {
    id: {
      type: String,
      default: "jalisco-map",
    },
    percentaje: {
      default: 0,
    },
    modelDetailsChart: Object,
  },
  data() {
    return {
      chart: {},
      utilFront: UtilFront,
      doughut: [],
    };
  },
  methods: {
    complement() {
      let icon = "";
      let title = "";
      let titleFooter = "";
      switch (this.id) {
        case "general":
          title = "JALISCO";
          icon = "feather icon-bar-chart-2 mr-1";
          titleFooter = "PORCENTAJE GENERAL DE ABASTO";
          break;
        case "max_percentaje":
          title = this.percentaje.jurisdiction;
          icon = "feather icon-trending-up mr-1";
          titleFooter = "JURISDICCIÓN CON MAYOR ABASTO";
          break;
        case "min_percentaje":
          title = this.percentaje.jurisdiction;
          icon = "feather icon-trending-down mr-1";
          titleFooter = "JURISDICCIÓN CON MENOR ABASTO";
          break;
      }
      return [icon, title, titleFooter];
    },
    /**
     * Refresca las graficas con los nuevos datos generados al cambio de clave
     * @method
     */
    refreshSupplyChart() {
      this.createdChart(false);
    },
    createdChart(val) {},
  },
};
</script>
<style scoped>
canvas {
  margin: auto;
  width: 150px !important;
  height: 75px !important;
}
</style>
<style scoped src="@/other/assets/css/app.css"></style>
