var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('v-data-table',{staticClass:"p-4 br",attrs:{"headers":_vm.headers,"items":_vm.dataList,"search":_vm.search,"fixed-header":"","height":"auto","footer-props":{
        'items-per-page-text': _vm.$t('table.item_table'),
        'items-per-page-all-text': _vm.$t('table.all'),
        'items-per-page-options': [-1],
      },"header-props":{ sortByText: 'Ordenar por' },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-4 col-xl-4 pb-0"},[_c('v-text-field',{staticClass:"br",attrs:{"outlined":"","dense":"","append-icon":"feather icon-search","label":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.quantityS",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.quantityS).format("0,0"))+" ")]}},{key:"item.quantityD",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.quantityD).format("0,0"))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.numeral(_vm.getSumProductsDispensing).format("0,0"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.numeral(_vm.getSumMedicalPrescription).format("0,0"))+" ")])])])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }