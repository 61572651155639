<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <v-data-table
        :headers="headers"
        :items="listFilter"
        :search="search"
        class="p-4 br"
        fixed-header
        height="auto"
        :footer-props="{
          'items-per-page-text': $t('table.item_table'),
          'items-per-page-all-text': $t('table.all'),
          'items-per-page-options': [-1],
        }"
        :loading-text="$t('table.loading')"
        :no-data-text="$t('table.no_results_available')"
        :no-results-text="$t('table.no_results_search')"
        dense
        :header-props="{ sortByText: 'Ordenar por' }"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
              <h1>{{ title }} {{ dataList.length }} pz.</h1>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3">
              <v-text-field
                outlined
                dense
                class="br"
                append-icon="feather icon-search"
                v-model="search"
                :label="$t('table.search')"
                hide-details
              ></v-text-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3">
              <v-select
                class="br"
                label="Claves Dispensadas"
                dense
                clear-icon="feather icon-x-circle"
                :items="filter"
                clearable
                append-icon="feather icon-chevron-down"
                v-model="dispensingChip"
                outlined
              ></v-select>
            </div>
          </div>
        </template>
        <template v-slot:[`item.quantityPrescription`]="{ item }">
          <div
            class="d-flex justify-content-center"
            v-if="item.quantityPrescription"
          >
            <i class="feather icon-flag fa-2x" style="color:#77c58d"></i>
          </div>
          <div class="d-flex justify-content-center" v-else>
            <i class="feather icon-flag fa-2x" style="color: #EDEDED"></i>
          </div>
        </template>
        <template v-slot:[`item.cpmMin`]="{ item }">
          <div v-if="item.cpmMin">
            {{ item.cpmMin }}
          </div>
          <div v-else>
            0
          </div>
        </template>
        <template v-slot:[`item.cpmMax`]="{ item }">
          <div v-if="item.cpmMax">
            {{ item.cpmMax }}
          </div>
          <div v-else>
            0
          </div>
        </template>
        <template v-slot:[`item.tag`]="{ item }">
          <div class="d-flex justify-content-center" v-if="item.tag">
            <i class="fa fa-check fa-2x" style="color:green"></i>
          </div>
          <div class="d-flex justify-content-center" v-else>
            <i class="fa fa-check fa-2x" style="color: #EDEDED"></i>
          </div>
        </template>
        <template v-slot:[`item.percentage`]="{ item }">
          <v-progress-linear
            v-if="!$vuetify.breakpoint.xsOnly"
            v-bind:color="utilFront.colorPercentajeTable(item.percentage)"
            height="18"
            class="br"
            :value="item.percentage"
            rounded
            :dark="item.percentage == 0 || item.percentage != undefined"
          >
            <strong v-if="item.percentage == 0 || item.percentage == undefined"
              >-</strong
            >
            <strong v-else-if="item.percentage > 100">100%</strong>
            <strong v-else>{{ Math.ceil(item.percentage) }}%</strong>
          </v-progress-linear>
          <div v-if="$vuetify.breakpoint.xsOnly">
            <strong v-if="item.percentage == 0 || item.percentage == undefined"
              >-</strong
            >
            <strong v-else-if="item.percentage > 100">100%</strong>
            <strong v-else>{{ Math.ceil(item.percentage) }}%</strong>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top v-if="item.percentage && item.percentage > 100 && isDetail">
            <template v-slot:activator="{ on, attrs }">
              <span class="mr-2" v-bind="attrs" v-on="on">
                <i class="feather icon-percent"></i>
              </span>
            </template>
            <span>{{ item.percentage ? item.percentage : 0 }}%</span>
          </v-tooltip>
          <span
            v-if=" item.percentage <= 100 && isDetail"
            class="mr-2"
            top
          >
            <i style="color: #adb5bd" class="feather icon-percent"></i>
          </span>

          <v-tooltip v-if="item.quantityPrescription" top>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="hand mr-2"
                @click="onChangeTableItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <i class="feather icon-eye"></i>
              </span>
            </template>
            <span>Recetas</span>
          </v-tooltip>

          <span v-if="!item.quantityPrescription" class="mr-2" top>
            <i style="color: #adb5bd" class="feather icon-eye"></i>
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import { UtilFront } from "@/other/utils";
import { mapState } from "vuex";

export default {
  props: {
    headers: {},
    dataList: {
      default: [],
    },
    isExcel: true,
    isDetail: true,
    title: "",
    onChangeTable: Function,
  },
  data() {
    return {
      search: "",
      utilFront: UtilFront,
      filter: [
        {
          text: "Dispensadas",
          value: true,
        },
        {
          text: "No Dispensadas",
          value: false,
        },
      ],
      filterDistributions: [
        {
          text: "Claves Dentro del Cuadro de Distribución",
          value: true,
        },
        {
          text: "Claves Fuera del Cuadro de Distribución",
          value: false,
        },
      ],
      dispensingChip: null,
      distributionChip: null,
    };
  },
  computed: {
    // Extración del state
    ...mapState("branchOffice", {
      generalAverage: "generalAverage",
    }),
    listFilter() {
      let list = [];
      if (this.dispensingChip != null || this.distributionChip != null) {
        list = this.dataList.filter((data) => {
          return this.dispensingChip != null && this.distributionChip != null
            ? data.quantityPrescription == this.dispensingChip &&
                data.tag == this.distributionChip
            : this.dispensingChip != null
            ? data.quantityPrescription == this.dispensingChip
            : data.tag == this.distributionChip;
        });
        return list;
      } else {
        return this.dataList;
      }
    },
  },
  methods: {
    onChangeTableItem(item) {
      this.onChangeTable(item);
    },
  },
  components: {
    ButtonTableComponent,
  },
  watch: {
    generalAverage: {
      handler: function(val) {
        this.search = "";
        this.dispensingChip = null;
        this.distributionChip = null;
      },
      deep: true,
    },
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
