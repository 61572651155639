<template>
  <div class="row align-items-center">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card text-center">
        <div class="card-body pb-0">
          <svg :id="id" class="svg-container-map"></svg>
          <div id="tooltipIdState" class="tooltipDiv ">
            <span id="tooltipSpanState"></span>
          </div>
        </div>
        <div v-show="nameMap" class="card-footer border-0">
          <h6>
            <i class="feather icon-map-pin mr-2"></i>{{ nameMap.toUpperCase() }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import svg from "svg.js/dist/svg";
import { UtilFront } from "@/other/utils";
import { mapState } from "vuex";

export default {
  name: "JaliscoMapComponent",
  props: {
    id: {
      type: String,
      default: "jalisco-map",
    },
  },
  data() {
    return {
      scale: 1,
      map: [],
      svgContainer: [],
      mapAttr: {
        viewBoxWidth: 650,
        viewBoxHeight: 650,
      },
      nameMap: "",
    };
  },
  computed: {
    // Extración del state
    ...mapState("jurisdiction", {
      modelJurisdiction: "modelJurisdiction",
    }),
  },
  mounted() {
    this.generateMap(this.modelJurisdiction.currentJurisdictionID);
  },
  methods: {
    generateMap(id) {
      this.nameMap = "";
      this.map = UtilFront.getMapStateJalisco(id);
      document.getElementById(this.id).innerHTML = "";
      const svgContainer = svg(this.id).viewbox(
        0,
        0,
        this.mapAttr.viewBoxWidth,
        this.mapAttr.viewBoxHeight
      );
      this.map.forEach((pathObj) => {
        this.generatePath(svgContainer, pathObj);
      });
    },
    generatePath(svgCont, pathObj) {
      const attrs = {
        stroke: "#343a40",
        "stroke-width": 1,
        province: pathObj["@name"],
        "map-id": pathObj["@id"],
        jurisdiction: pathObj["@jurisdiccion"],
        jurisdictionName: pathObj["@jurisdiccionName"],
        class: "svg-container-map",
      };
      attrs.fill = UtilFront.colorMap(parseInt(pathObj["@jurisdiccion"]));
      const province = svgCont.path(pathObj["@d"]).attr(attrs);
      province.mouseover((element) => {
        this.nameMap = element.target.attributes.province.value;
      });
    },
  },
  watch: {
    "modelJurisdiction.currentJurisdictionID": {
      handler: function(val) {
        this.generateMap(val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
svg .svg-container-map {
  position: relative;
  cursor: default;
  transition: all 0.2s ease-in-out;
  &:hover {
    fill-opacity: 0.8;
  }
}
</style>
