<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card">
        <div style="position:absolute;" class="card-header pl-2 pt-1 p-0">
          <b-tooltip target="button-1"
            ><span class="letter"
              >Total de envíos hechos de los cedis a todas los centros de salud
              y hospitales disponibles de enero 2022 a la actualidad.</span
            >
          </b-tooltip>

          <i id="button-1" class="feather icon-globe info-color"></i>
        </div>
        <div class="card-body">
          <div class="row align-items-center ">
            <div class="col-4 text-center align-center">
              <h3 class="h3">
                <i class="feather icon-truck fa-2x mr-3"></i>
              </h3>
            </div>
            <div class="col-8 pl-0">
              <span class="font-12 text-uppercase"
                >Enero 2022 - Actualidad</span
              >

              <ul class="list-inline mb-0 mt-1">
                <li>
                  <h4 class="mb-2">
                    {{ numeral(shipmentCount).format("0,0") }}
                  </h4>
                </li>
              </ul>
              <h5 class="font-15">ENVÍOS REALIZADOS A TODO EL ESTADO</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card">
        <div style="position:absolute;" class="card-header pl-2 pt-1 p-0">
          <b-tooltip target="button-2"
            ><span class="letter"
              >Total de recetas dispensadas en todas las farmacias del estado de
              enero 2022 a la actualidad.</span
            >
          </b-tooltip>

          <i id="button-2" class="feather icon-globe info-color"></i>
        </div>
        <div class="card-body">
          <div class="row align-items-center ">
            <div class="col-4 text-center align-center">
              <h3 class="h3">
                <i class="feather icon-user-plus fa-2x"></i>
              </h3>
            </div>
            <div class="col-8 pl-0">
              <span class="font-12 text-uppercase"
                >Enero 2022 - Actualidad</span
              >

              <ul class="list-inline mb-0 mt-1">
                <li>
                  <h4 class="mb-2">
                    {{ numeral(prescriptionCount).format("0,0") }}
                  </h4>
                </li>
              </ul>
              <h5 class="font-15">RECETAS SURTIDAS A TODO EL ESTADO</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
      <div class="card">
        <div style="position:absolute;" class="card-header pl-2 pt-1 p-0">
          <b-tooltip target="button-3"
            ><span class="letter"
              >Total de piezas dispensadas en todas las farmacias del estado de
              enero 2022 a la actualidad.</span
            >
          </b-tooltip>
          <i id="button-3" class="feather icon-globe info-color"></i>
        </div>
        <div class="card-body">
          <div class="row align-items-center ">
            <div class="col-4 text-center align-center">
              <h3 class="h3">
                <i class="feather icon-package fa-2x"></i>
              </h3>
            </div>
            <div class="col-8 pl-0">
              <span class="font-12 text-uppercase"
                >Enero 2022 - Actualidad</span
              >
              <ul class="list-inline mb-0 mt-1">
                <li>
                  <h4 class="mb-2">
                    {{ numeral(pieceCount).format("0,0") }}
                  </h4>
                </li>
              </ul>
              <h5 class="font-15">PIEZAS DISPENSADAS A TODO EL ESTADO</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gsap from "gsap";

export default {
  data() {
    return {
      numeral: require("numeral"),
      pieceCount: 0,
      prescriptionCount: 0,
      shipmentCount: 0,
    };
  },
  mounted() {},
  computed: {
    // Extración del state
    ...mapState("supply", {
      modelDetails: "modelDetails",
    }),
  },
  created() {
    gsap.to(this, { duration: 0.5, pieceCount: this.modelDetails.pieceCount });
    gsap.to(this, {
      duration: 0.5,
      shipmentCount: this.modelDetails.shipmentCount,
    });
    gsap.to(this, {
      duration: 0.5,
      prescriptionCount: this.modelDetails.prescriptionCount,
    });
  },
  watch: {
    "modelDetails.pieceCount": {
      handler: function(val) {
        gsap.to(this, { duration: 0.5, pieceCount: val });
      },
    },
    "modelDetails.prescriptionCount": {
      handler: function(val) {
        gsap.to(this, { duration: 0.5, prescriptionCount: val });
      },
    },
    "modelDetails.shipmentCount": {
      handler: function(val) {
        gsap.to(this, { duration: 0.5, shipmentCount: val });
      },
    },
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
