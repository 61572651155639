<template>
  <div>
    <div class="card text-center">
      <div class="card-header border-0"><h4>ESTADO DE JALISCO</h4></div>
      <div class="card-body p-0 border-0">
        <svg
          :id="id"
          v-bind:class="[nameMap ? 'svg-container-radius' : 'svg-container']"
        ></svg>
        <div id="tooltipId" class="tooltipDiv">
          <div id="tooltipSpan"></div>
        </div>
      </div>
      <div v-show="nameMap" class="card-footer border-0">
        <h6><i class="feather icon-map-pin mr-2"></i>{{ nameMap }}</h6>
      </div>
    </div>
  </div>
</template>
<script>
import provinces from "@/other/assets/json/jalisco-provinces.json";
import svg from "svg.js/dist/svg";
import { UtilFront } from "@/other/utils";
/* Vuex */
import { mapState } from "vuex";
export default {
  name: "JaliscoMapComponent",
  props: {
    id: {
      type: String,
      default: "jalisco-map",
    },
  },
  data() {
    return {
      scale: 1,
      mapAttr: {
        viewBoxWidth: 648,
        viewBoxHeight: 600,
      },
      nameMap: "",
      mapDisabled: [],
    };
  },
  mounted() {
    this.generateMap();
  },
  computed: {
    // Extración del state
    ...mapState("supply", {
      modelDetailsChart: "modelDetailsChart",
    }),
  },
  methods: {
    async deleteProvinces(provincesFilter) {
      provinces.map((province) => {
        province["@enabled"] = false;
        provincesFilter.map((proviceFilter) => {
          if (proviceFilter.jurisdictionId == province["@jurisdiccion"]) {
            province["@enabled"] = true;
          }
        });
      });
    },
    async generateMap() {
      this.nameMap = "";
      document.getElementById(this.id).innerHTML = "";
      const svgContainer = svg(this.id)
        .size("100%", "100%")
        .viewbox(3, 3, this.mapAttr.viewBoxWidth, this.mapAttr.viewBoxHeight);
      await this.deleteProvinces(this.modelDetailsChart.percentageList);
      provinces.forEach((pathObj) => {
        this.generatePath(svgContainer, pathObj);
      });
    },
    generatePath(svgCont, pathObj) {
      const attrs = {
        stroke: "#343a40",
        "stroke-width": 0.65,
        province: pathObj["@name"],
        "map-id": pathObj["@id"],
        jurisdiction: pathObj["@jurisdiccion"],
        jurisdictionName: pathObj["@jurisdiccionName"],
      };

      if (
        !(pathObj["@jurisdiccion"] == "0" || pathObj["@jurisdiccion"] == "1")
      ) {
        attrs.fill = !pathObj["@enabled"]
          ? "#f8f9fa"
          : UtilFront.colorMap(parseInt(pathObj["@jurisdiccion"]));
        attrs.class = !pathObj["@enabled"] ? "no-click" : "svg-container";
        const province = svgCont.path(pathObj["@d"]).attr(attrs);
        if (pathObj["@enabled"]) {
          province.mouseover((element) => {
            const province = element.target.attributes.province.value;
            const jurisdictionName =
              element.target.attributes.jurisdictionName.value;

            this.nameMap = jurisdictionName.toUpperCase();
          });
          province.click((element) => {
            const mapId = element.target.attributes["map-id"].value;
            const province = element.target.attributes.province.value;
            const jurisdiction = element.target.attributes.jurisdiction.value;
            const jurisdictionName =
              element.target.attributes.jurisdictionName.value;

            this.$emit("mapClick", {
              id: mapId,
              province: province,
              idJurisdiction: jurisdiction,
              currentJurisdiction: jurisdictionName.toUpperCase(),
            });
          });
        }
      } else {
        attrs.class = "no-click";
        /* const province = svgCont.path(pathObj["@d"]).attr(attrs); */
      }
    },
  },
  watch: {
    "modelDetailsChart.percentageList": {
      handler: async function() {
        await this.generateMap();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.tooltipDiv {
  height: 25px;
  border-color: rgba(0, 0, 0, 1);
  border-radius: 8px;
  border-width: thin;
  border-style: solid;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  position: absolute;
  display: none;
}

.tooltipDiv span {
  margin: 10px;
}

.svg-container-radius {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.svg-container {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

svg .no-click {
  cursor: default;
}

svg .svg-container {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    fill-opacity: 0.8;
  }
}
</style>
<style scoped src="@/other/assets/css/app.css"></style>
