<template>
  <div class="row ">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div
        class="card-header d-flex justify-content-between align-items-center  mt-2"
      >
        <h4 class="m-0">ABASTO POR JURISDICCIÓN</h4>
        <button
          type="button"
          @click="excelByBranchOfficeCH()"
          class="btn btn-outline-success br"
        >
          <i class="feather icon-download mr-2"></i> Descargar (xlsx)
        </button>
      </div>
      <div class="card-body border-0 pt-0">
        <canvas v-on:click="getJurisdictionBar" id="jurisdiction"></canvas>
      </div>
      <div v-show="nameJurisdictions" class="card-footer text-center border-0">
        <h5>
          <i class="feather icon-bar-chart-2 mr-2"></i>{{ nameJurisdictions }}
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
import { UtilFront } from "@/other/utils";
import { DashboardRequest } from "@/other/core";
import moment from "moment";
/* Vuex */
import { mapState } from "vuex";

export default {
  props: {
    modelDetailsChart: Object,
    mapClick: Function,
  },
  data() {
    return {
      jurisdictionData: [],
      namesJurisdictions: [],
      chartJurisdiction: {},
      jurisdictionDataComponent: {},
      dataSet: [],
      indexs: 0,
      nameJurisdictions: "",
    };
  },
  mounted() {
    //Despliego el los porcentajes de de las jurisdiciones
    const ctxJurisdiction = document.getElementById("jurisdiction");
    ctxJurisdiction.height = 500;
    window.char_js = this;
    this.chartJurisdiction = new Chart(ctxJurisdiction, this.jurisdictionBar);
  },
  computed: {
    // Extración del state
    ...mapState("global", {
      idDistributionBoard: "idDistributionBoard",
    }),
  },
  methods: {
    async excelByBranchOfficeCH() {
      await DashboardRequest.reportDashboardBranchOffice(
        {
          type: 0,
          jurisdiction: 0,
          dateDashboard: moment().format("YYYY-MM-DD"),
          dashboardType: 1,
          distributionBoard: this.idDistributionBoard,
        },
        { name: "porcentajeSucursal.xlsx" }
      );
      this.$toast.success("Reporte generado (porcentajeSucursal.xlsx)");
    },
    refreshJurisdictionsBar() {
      this.namesJurisdictions = [];
      this.modelDetailsChart.percentageList.map((jurisdiction) => {
        this.namesJurisdictions.push(
          UtilFront.getMapStateJaliscoNum(jurisdiction.jurisdictionId)
        );
      });

      let dataChart = [];
      this.modelDetailsChart.percentageList.map((percentage) => {
        if (percentage.percentage > 100) {
          dataChart.push(100);
        } else {
          dataChart.push(percentage.percentage);
        }
      });
      this.chartJurisdiction.data.datasets[0].data = dataChart;
      this.chartJurisdiction.data.labels = this.namesJurisdictions;
      let color = [];
      this.modelDetailsChart.percentageList.map((jurisdiction) => {
        color.push(UtilFront.colorBar(jurisdiction.jurisdictionId));
      });
      this.chartJurisdiction.data.datasets[0].backgroundColor = color;
      this.chartJurisdiction.update();
    },
    getJurisdictionBar(evt) {
      var activePoints = this.chartJurisdiction.getElementsAtEvent(evt);
      if (activePoints[0]) {
        let payload = {};
        let idJurisdiction = UtilFront.getMapStateJaliscoNumRomanos(
          activePoints[0]["_model"].label
        );
        this.modelDetailsChart.percentageList.map((jurisdiction) => {
          if (idJurisdiction == jurisdiction.jurisdictionId) {
            payload = {
              province: jurisdiction.jurisdictionName,
              idJurisdiction: jurisdiction.jurisdictionId,
            };
          }
        });
        this.$emit("mapClick", payload);
      }
    },
    getJurisdiction: function(id) {
      let val = UtilFront.getMapStateJaliscoNumRomanos(id);
      this.modelDetailsChart.percentageList.map((jurisdiction) => {
        if (val == jurisdiction.jurisdictionId) {
          this.nameJurisdictions = jurisdiction.jurisdictionName;
        }
      });
    },
  },
  created() {
    this.namesJurisdictions = [];
    this.modelDetailsChart.percentageList.map((jurisdiction) => {
      this.namesJurisdictions.push(
        UtilFront.getMapStateJaliscoNum(jurisdiction.jurisdictionId)
      );
    });

    let dataChart = [];
    this.modelDetailsChart.percentageList.map((percentage) => {
      if (percentage.percentage > 100) {
        dataChart.push(100);
      } else {
        dataChart.push(percentage.percentage);
      }
    });
    let color = [];
    this.modelDetailsChart.percentageList.map((jurisdiction) => {
      color.push(UtilFront.colorBar(jurisdiction.jurisdictionId));
    });
    this.dataSet = {
      label: "Porcentaje de Abasto por Jurisdicción",
      data: dataChart,
      backgroundColor: color,
      borderWidth: 0,
      borderSkipped: false,
    };

    this.jurisdictionData = {
      labels: this.namesJurisdictions,
      datasets: [this.dataSet],
    };

    //Porcentaje por jurisdicciones
    this.jurisdictionBar = {
      type: screen.width < 576 ? "horizontalBar" : "bar",
      data: this.jurisdictionData,
      options: {
        tooltips: {
          enabled: false,
          custom: function(tooltipModel) {
            if (tooltipModel.dataPoints != undefined) {
              window.char_js.getJurisdiction(tooltipModel.dataPoints[0].label);
            }
          },
        },
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 50,
            bottom: 0,
          },
        },
        maintainAspectRatio: false,
        hover: {
          animationDuration: 0,
        },
        animation: {
          animation: false,
          scaleOverride: true,
          scaleSteps: 1,
          scaleStepWidth: 1,
          onComplete: function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontSize,
              Chart.defaults.global.defaultFontStyle,
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            if (screen.width < 576) {
              this.data.datasets.forEach(function(dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function(bar, index) {
                  /* var centerPoint = bar.getCenterPoint(); */
                  var data = dataset.data[index] + "%";
                  ctx.fillStyle = "#fff";
                  ctx.font = "bold 10pt Roboto-Regular";
                  ctx.fillText(data, bar._model.x - 40, bar._model.y);
                });
              });
            } else {
              this.data.datasets.forEach(function(dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function(bar, index) {
                  var data = dataset.data[index] + "%";
                  /* var centerPoint = bar.getCenterPoint(); */
                  ctx.fillStyle = "#212529";
                  ctx.font = "bold 10pt Roboto-Regular";
                  ctx.fillText(data, bar._model.x, bar._model.y - 10);
                });
              });
            }
          },
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                autoSkip: false,

                fontFamily: "'Roboto-Regular'",
                borderRadius: 10,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 100,
                min: 0,
                padding: 10,
                fontSize: 13,
                fontFamily: "'Roboto-Regular'",
              },
            },
          ],
        },
        legend: {
          display: false,
          labels: {
            fontColor: "black",
            fontSize: 20,
            fontFamily: "'Roboto-Regular'",
          },
        },
      },
    };
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
