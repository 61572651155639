<template>
  <v-dialog
    max-width="1200"
    :fullscreen="$vuetify.breakpoint.mobile"
    v-model="dialog.dialog"
    scrollable
  >
    <v-card>
      <v-card-title v-bind:style="{ display: 'block' }" class="p-0">
        <div
          v-bind:class="$vuetify.breakpoint.mobile ? 'p-2 pb-0' : 'p-4 pb-0'"
        >
          <v-toolbar dark class="header">
            <v-toolbar-title class="pt-4">
              <p class="text-center">
                Recetas dispensadas
              </p>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="dialog.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div
            v-bind:class="
              $vuetify.breakpoint.mobile
                ? 'card-body pt-5 p-0'
                : 'card-body p-3'
            "
          >
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                <p class="text--primary">
                  <i class="feather icon-tag mr-2 text-fmedical-blue"></i>
                  <strong>
                    <span>Clave CNIS: </span>
                  </strong>
                  {{ cluesKey }}
                </p>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p class="text--primary">
                  <i class="feather icon-package mr-2 text-fmedical-blue"></i>
                  <strong>
                    <span> Producto: </span>
                  </strong>
                  {{ nameProduct }}
                </p>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <v-data-table
                  :headers="headers"
                  :items="dispensingData"
                  :search="search"
                  fixed-header
                  height="auto"
                  :footer-props="{
                    'items-per-page-text': $t('table.item_table'),
                    'items-per-page-all-text': $t('table.all'),
                    'items-per-page-options': [-1],
                  }"
                  :loading-text="$t('table.loading')"
                  :no-data-text="$t('table.no_results_available')"
                  :no-results-text="$t('table.no_results_search')"
                  dense
                  :header-props="{ sortByText: 'Ordenar por' }"

                >
                  <template v-slot:top>
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 pb-0">
                        <v-text-field
                          outlined
                          dense
                          class="br"
                          append-icon="feather icon-search"
                          v-model="search"
                          :label="$t('table.search')"
                        ></v-text-field>
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.supplyPercentage`]="{ item }">
                    <v-progress-linear
                    v-if="!$vuetify.breakpoint.xsOnly"

                      v-bind:color="
                        utilFront.colorPercentajeTable(
                          parseInt(item.supplyPercentage.replace('%', ''))
                        )
                      "
                      height="18"
                      :value="parseInt(item.supplyPercentage.replace('%', ''))"
                      rounded
                      dark
                      class="br"
                    >
                      <strong
                        v-if="
                          parseInt(item.supplyPercentage.replace('%', '')) == 0
                        "
                        >-</strong
                      >
                      <strong
                        v-else-if="
                          parseInt(item.supplyPercentage.replace('%', '')) > 100
                        "
                        >100%</strong
                      >
                      <strong v-else
                        >{{
                          Math.ceil(
                            parseInt(item.supplyPercentage.replace("%", ""))
                          )
                        }}%</strong
                      >
                    </v-progress-linear>
                    <div v-if="$vuetify.breakpoint.xsOnly">
                      <strong
                        v-if="
                          parseInt(item.supplyPercentage.replace('%', '')) == 0
                        "
                        >-</strong
                      >
                      <strong
                        v-else-if="
                          parseInt(item.supplyPercentage.replace('%', '')) > 100
                        "
                        >100%</strong
                      >
                      <strong v-else
                        >{{
                          Math.ceil(
                            parseInt(item.supplyPercentage.replace("%", ""))
                          )
                        }}%</strong
                      >
          </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="hand text-fmedical-red"
                          @click="onChangeTableItem(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="feather icon-file-text mr-2"></i>
                        </span>
                      </template>
                      <span>PDF</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { BRANCH_OFFICE_BY_DISPENCING } from "@/other/utils/DataTableHeaders";
import { UtilFront } from "@/other/utils";
import { DashboardRequest } from "@/other/core";

export default {
  props: {
    dialog: Object,
  },
  data() {
    return {
      search: "",
      headers: BRANCH_OFFICE_BY_DISPENCING(),
      utilFront: UtilFront,
    };
  },
  computed: {
    // Extración del state
    ...mapState("branchOffice", {
      dispensingData: "dispensingData",
      cluesKey: "cluesKey",
      nameProduct: "nameProduct",
    }),
  },
  methods: {
    onChangeTableItem(item) {
      let send =
        "idOrder=" + item.idOrder + "&idPrescription=" + item.idPrescription;
      DashboardRequest.reportPrescription(send);
      this.$toast.success("Reporte generado (documento.pdf)");
    },
  },
  watch: {
    "dialog.dialog": {
      handler: function(val) {
        if (val) {
          this.search = "";
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
<style scoped>
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.header {
  background-color: #023047 !important;
  color: white !important;
  border-radius: 9px;
}
</style>
