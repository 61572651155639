<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card">
        <div
          v-if="
            modelUser.userListBranchOffice &&
              modelUser.userListBranchOffice.length > 1
          "
          class="card-header p-0 ml-2 mt-1"
        >
          <b-tooltip target="messagues"
            ><span class="letter"
              >Este usuario cuenta con más de una sucursal asignada, para
              realizar un cambio de sucursal da click en el icono
              <i class="feather icon-chevron-down"></i> o en el nombre de la
              sucursal .</span
            >
          </b-tooltip>
          <i id="messagues" class="hand feather icon-info info-color"></i>
        </div>

        <div class="card-body">
          <div class="media">
            <div class="media-body text-center">
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 p-0">
                  <template
                    v-if="
                      modelUser.userListBranchOffice &&
                        modelUser.userListBranchOffice.length > 1
                    "
                  >
                    <v-menu>
                      <template v-slot:activator="{ attrs, on }">
                        <h2 class="title-color" v-bind="attrs" v-on="on">
                          {{ modelBranchOffice.nameBranchOffice }}
                          <i class="feather icon-chevron-down"></i>
                        </h2>
                        <!--  <h4 class="card-title font-18 pt-1">
                          {{ modelBranchOffice.zone }} |
                          {{ modelBranchOffice.branchOfficeKey }}
                        </h4> -->
                      </template>
                      <v-list>
                        <v-list-item-group v-model="selectedItem">
                          <v-list-item
                            v-for="item in modelUser.userListBranchOffice"
                            :key="item.idBranchOffice"
                          >
                            <v-list-item-title
                            >{{ item.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </template>
                  <h2 v-else>{{ modelBranchOffice.nameBranchOffice }}</h2>
                </div>
              </div>
              <h4
                v-show="modelBranchOffice.zone != ''"
                class="card-title font-18 pt-1"
              >
                {{ modelBranchOffice.zone }} | {{ modelBranchOffice.clues }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      branchOffice: "",
      selectedItem: 0,
    };
  },
  methods: {
    ...mapMutations("global", {
      updateSelect: "LEVEL_BRANCH_SELECT",
    }),
    async changeBranchOffice() {
      let payloadJurisdiction = {
        branchOfficeId: this.modelUser.userListBranchOffice[this.branchSelection]
          .idBranchOffice,
        distributionBoardId: this.idDistributionBoard,
        nameBranchOffice: this.modelUser.userListBranchOffice[
          this.branchSelection
        ],
        selectBranches: this.branchSelection,
      };
      let payloadDetail = {
        jurisdictionId: this.modelUser.userListBranchOffice[
          this.branchSelection
        ].jurisdiction,
        branchOfficeId: this.modelUser.userListBranchOffice[this.branchSelection]
          .idBranchOffice,
      };
      await this.dashboardBranchOficceDetailAction(payloadJurisdiction);
      await this.dashboardBranchDetailAction(payloadDetail);
    },
    ...mapActions("branchOffice", {
      dashboardBranchOficceDetailAction: "dashboardBranchOficceDetailAction",
      dashboardBranchDetailAction: "dashboardBranchDetailAction",
    }),
  },
  computed: {
    // Extración del state
    ...mapState("branchOffice", {
      modelBranchOffice: "modelBranchOffice",
    }),
    ...mapState("global", {
      modelUser: "modelUser",
      idDistributionBoard: "idDistributionBoard",
      branchSelection: "branchSelection",
    }),
    ...mapState("jurisdiction", {
      modelJurisdiction: "modelJurisdiction",
    }),
  },
  watch: {
    selectedItem() {
      if (this.selectedItem != undefined) {
        this.updateSelect(this.selectedItem);
        this.changeBranchOffice();
      }
    },
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
<style scoped>
.title-color {
  color: #263a5b !important;
}
</style>
