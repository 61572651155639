var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('v-data-table',{staticClass:"p-4 br",attrs:{"headers":_vm.headers,"items":_vm.listFilter,"search":_vm.search,"fixed-header":"","height":"auto","footer-props":{
        'items-per-page-text': _vm.$t('table.item_table'),
        'items-per-page-all-text': _vm.$t('table.all'),
        'items-per-page-options': [-1],
      },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":"","header-props":{ sortByText: 'Ordenar por' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"},[_c('h1',[_vm._v(_vm._s(_vm.title)+" "+_vm._s(_vm.dataList.length)+" pz.")])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4 col-xl-3"},[_c('v-text-field',{staticClass:"br",attrs:{"outlined":"","dense":"","append-icon":"feather icon-search","label":_vm.$t('table.search'),"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4 col-xl-3"},[_c('v-select',{staticClass:"br",attrs:{"label":"Claves Dispensadas","dense":"","clear-icon":"feather icon-x-circle","items":_vm.filter,"clearable":"","append-icon":"feather icon-chevron-down","outlined":""},model:{value:(_vm.dispensingChip),callback:function ($$v) {_vm.dispensingChip=$$v},expression:"dispensingChip"}})],1)])]},proxy:true},{key:"item.quantityPrescription",fn:function(ref){
      var item = ref.item;
return [(item.quantityPrescription)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('i',{staticClass:"feather icon-flag fa-2x",staticStyle:{"color":"#77c58d"}})]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('i',{staticClass:"feather icon-flag fa-2x",staticStyle:{"color":"#EDEDED"}})])]}},{key:"item.cpmMin",fn:function(ref){
      var item = ref.item;
return [(item.cpmMin)?_c('div',[_vm._v(" "+_vm._s(item.cpmMin)+" ")]):_c('div',[_vm._v(" 0 ")])]}},{key:"item.cpmMax",fn:function(ref){
      var item = ref.item;
return [(item.cpmMax)?_c('div',[_vm._v(" "+_vm._s(item.cpmMax)+" ")]):_c('div',[_vm._v(" 0 ")])]}},{key:"item.tag",fn:function(ref){
      var item = ref.item;
return [(item.tag)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('i',{staticClass:"fa fa-check fa-2x",staticStyle:{"color":"green"}})]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('i',{staticClass:"fa fa-check fa-2x",staticStyle:{"color":"#EDEDED"}})])]}},{key:"item.percentage",fn:function(ref){
      var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-progress-linear',{staticClass:"br",attrs:{"color":_vm.utilFront.colorPercentajeTable(item.percentage),"height":"18","value":item.percentage,"rounded":"","dark":item.percentage == 0 || item.percentage != undefined}},[(item.percentage == 0 || item.percentage == undefined)?_c('strong',[_vm._v("-")]):(item.percentage > 100)?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil(item.percentage))+"%")])]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',[(item.percentage == 0 || item.percentage == undefined)?_c('strong',[_vm._v("-")]):(item.percentage > 100)?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil(item.percentage))+"%")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(item.percentage && item.percentage > 100 && _vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mr-2"},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-percent"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.percentage ? item.percentage : 0)+"%")])]):_vm._e(),( item.percentage <= 100 && _vm.isDetail)?_c('span',{staticClass:"mr-2",attrs:{"top":""}},[_c('i',{staticClass:"feather icon-percent",staticStyle:{"color":"#adb5bd"}})]):_vm._e(),(item.quantityPrescription)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.onChangeTableItem(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v("Recetas")])]):_vm._e(),(!item.quantityPrescription)?_c('span',{staticClass:"mr-2",attrs:{"top":""}},[_c('i',{staticClass:"feather icon-eye",staticStyle:{"color":"#adb5bd"}})]):_vm._e()]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }