<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <CardComponents />
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <div class="card">
          <div class="card-header text-center border">
            <h2>
              {{ modelJurisdiction.currentJurisdiction.toUpperCase() }}
            </h2>
          </div>
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <JaliscoMapComponent
                v-show="$vuetify.breakpoint.width > 767"
                id="jalisco_state"
              />
            </div>
            <v-divider class="mt-3 mb-3" inset vertical></v-divider>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <JaliscoDoughutCharComponent
                id="general_jurisdiction"
                ref="general_jurisdiction"
                :percentage="modelJurisdiction.generalAverage.average"
                :title="modelJurisdiction.generalAverage.jurisdiction"
                :modelJurisdiction="modelJurisdiction"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <PorcetageCardComponent :modelJurisdiction="modelJurisdiction" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <TableComponent
          :headers="headers"
          :dataList="modelJurisdiction.listJurisdictions"
          :onChangeTable="onChangeTable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardComponents from "@/other/components/jalisco/jurisdiction/CardComponents";
import JaliscoDoughutCharComponent from "@/other/components/jalisco/jurisdiction/JaliscoDoughutCharComponent";
import JaliscoMapComponent from "@/other/components/jalisco/jurisdiction/JaliscoMapComponent";
import TableComponent from "@/other/components/jalisco/jurisdiction/TableComponent";
import PorcetageCardComponent from "@/other/components/jalisco/jurisdiction/PorcetageCardComponent";
import { mapState, mapActions } from "vuex";
import { UtilFront } from "@/other/utils";

import { BRANCH_OFFICE_BY_JURISDICTION } from "@/other/utils/DataTableHeaders";
export default {
  data() {
    return {
      headers: BRANCH_OFFICE_BY_JURISDICTION(),
      utilFront: UtilFront,
    };
  },
  computed: {
    // Extración del state
    ...mapState("jurisdiction", {
      modelJurisdiction: "modelJurisdiction",
    }),
    ...mapState("global", {
      idDistributionBoard: "idDistributionBoard",
    }),
  },
  methods: {
    ...mapActions("branchOffice", {
      dashboardBranchOficceDetailAction: "dashboardBranchOficceDetailAction",
      dashboardBranchDetailAction: "dashboardBranchDetailAction",
    }),
    async onChangeTable(payload) {
      let payloadJurisdiction = {
        branchOfficeId: payload.idBranchOffice,
        distributionBoardId: this.idDistributionBoard,
        nameBranchOffice: payload,
      };
      let payloadDetail = {
        jurisdictionId: this.modelJurisdiction.currentJurisdictionID,
        branchOfficeId: payload.idBranchOffice,
      };
      await this.dashboardBranchOficceDetailAction(payloadJurisdiction);
      await this.dashboardBranchDetailAction(payloadDetail);
    },
  },
  watch: {
    modelJurisdiction: {
      handler: function(val) {
        this.$refs.general_jurisdiction.refreshSupplyChart(val);
      },
      deep: true,
    },
  },
  components: {
    CardComponents,
    JaliscoDoughutCharComponent,
    JaliscoMapComponent,
    TableComponent,
    PorcetageCardComponent,
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
<style scoped>
@media only screen and (min-width: 1200px) {
  .theme--light.v-divider {
    border-color: rgba(202, 202, 202, 0.3) !important;
  }
}
@media only screen and (max-width: 1200px) {
  .theme--light.v-dividert {
    border-color: 0px solid rgba(0, 0, 0, 0.03) !important;
  }
}
</style>
