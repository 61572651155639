<template>
  <div class="row align-items-center">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card ">
        <div class="card-header border text-center">
          <h4>{{ titleBar }}</h4>
        </div>

        <div class="card-body">
          <ul class="list-unstyled">
            <li>
              <i
                v-bind:class="[animation == 0 ? 'animation' : '']"
                style="color: rgba(100, 156, 124,1); "
                class="mdi mdi-circle mr-2"
              ></i>
              {{ quantityAuthorize }}
            </li>
            <li>
              <i
                v-bind:class="[animation == 1 ? 'animation' : '']"
                style="color: rgba(167, 220, 165 , 1)"
                class="mdi mdi-circle  mr-2"
              ></i>
              {{ quantityUnauthorized }}
            </li>
          </ul>
          <canvas id="porcentaje"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import Chart from 'chart.js'
import { mapState } from "vuex";

export default {
  data() {
    return {
      authorizedMedicationLabels: [],
      newDataset: {},
      authorizedMedicationData: {},
      titleBar: "",
      quantityAuthorize: "",
      quantityUnauthorized: "",
      animation: null,
    };
  },
  mounted() {
    window.char_js_branch = this;
    //Despliego el los porcentajes de las sucursales
    const ctxPercentage = document.getElementById("porcentaje");
    this.chartAuthorizedMedication = new Chart(
      ctxPercentage,
      this.authorizedMedicationBar
    );
    this.refreshPercentageBar("2");
  },
  watch: {
    /* Refreca la la grafica cuando se solicita nueva información */
    distributionBoard: {
      handler: function() {
        this.refreshPercentageBar("2");
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("branchOffice", {
      distributionBoard: "distributionBoard",
    }),
  },
  methods: {
    getJurisdiction(val) {
      this.animation = val;
    },
    /* Fuction que genera los titulos de la graficas */
    refreshPercentageBar(type) {
      let labelQuantityAuthorize = "";
      let labelQuantityUnauthorized = "";
      let label = "";
      switch (type) {
        case "2":
          this.quantityAuthorize =
            "Claves Dentro del Cuadro de Distribución (" +
            this.distributionBoard.authorizedProduct +
            ")";
          this.quantityUnauthorized =
            "Claves Fuera del Cuadro de Distribución (" +
            this.distributionBoard.unauthorizedProduct +
            ")";
          this.titleBar = "CLAVES ACREDITADAS";
          break;
      }

      this.newDataset.data = [
        this.distributionBoard.authorizedProduct,
        this.distributionBoard.unauthorizedProduct,
      ];
      /* this.newDataset.label = label;
       */
      this.authorizedMedicationData.labels.pop();
      this.authorizedMedicationData.labels.pop();

      this.authorizedMedicationData.labels.push(labelQuantityAuthorize);
      this.authorizedMedicationData.labels.push(labelQuantityUnauthorized);

      this.authorizedMedicationData.datasets.pop();
      this.authorizedMedicationData.datasets.push(this.newDataset);
      this.chartAuthorizedMedication.update();
    },
  },
  created() {
    this.authorizedMedicationLabels = [];

    this.newDataset = {
      data: [],
      label: "",
      backgroundColor: ["rgba(100, 156, 124 , .9)", "rgba(167, 220, 165 , .9)"],
      barThickness: 75,
      borderColor: "#649c7c",
      borderWidth: 0,
    };

    this.authorizedMedicationData = {
      labels: this.authorizedMedicationLabels,
      datasets: [this.newDataset],
    };

    this.authorizedMedicationBar = {
      type: "bar",
      data: this.authorizedMedicationData,
      options: {
        onHover: function(evt, elements) {
          if (elements && elements.length) {
            window.char_js_branch.getJurisdiction(elements[0]._index);
          } else {
            window.char_js_branch.getJurisdiction(null);
          }
        },
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: {
            top: 35,
          },
        },
        responsive: true,
        hover: {
          animationDuration: 0.3,
        },
        animation: {
          animation: false,
          scaleOverride: true,
          scaleSteps: 1,
          scaleStepWidth: 1,
          onComplete: function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontSize,
              Chart.defaults.global.defaultFontStyle,
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = dataset.data[index];
                /* var centerPoint = bar.getCenterPoint(); */
                ctx.fillStyle = "#212529";
                ctx.font = "bold 15pt Roboto-Regular";
                ctx.fillText(data, bar._model.x, bar._model.y - 10);
              });
            });
          },
        },
        responsive: true,
        lineTension: 1,
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 14,
                fontFamily: "'Roboto-Regular'",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                padding: 20,
                fontSize: 14,
                fontFamily: "'Roboto-Regular'",
              },
            },
          ],
        },
        legend: {
          display: false,
          labels: {
            borderSkipped: false,
            fontColor: "black",
            fontSize: 20,
            fontFamily: "Roboto-Regular",
          },
        },
      },
    };
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
<style lang="css">
.animation {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
