<template>
  <div class="row align-items-center">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-10 pt-2">
      <h2 v-show="percentage == 0" class="text-center">
        SIN PARAMETROS
      </h2>
      <div>
        <canvas v-show="percentage > 0" :id="id"></canvas>
      </div>
      <h5 v-show="percentage > 0" class="text-center font-17 mt-9">
        <i :class="icon()"></i>{{ titleCard() }}
      </h5>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
import { UtilFront } from "@/other/utils";

export default {
  props: {
    id: {
      type: String,
      default: "jalisco-map",
    },
    percentage: {
      default: 0,
    },
    title: {
      default: "",
    },
    modelJurisdiction: Object,
  },
  data() {
    return {
      chart: {},
      utilFront: UtilFront,
      doughut: [],
    };
  },
  mounted() {
    //Despliego el los porcentajes de de las jurisdiciones
    const ctxDougHut = document.getElementById(this.id);
    this.chart = new Chart(ctxDougHut, this.doughut);
  },
  methods: {
    icon() {
      let icon = "";
      switch (this.id) {
        case "general_jurisdiction":
          icon = "feather icon-bar-chart-2 mr-2";
          break;
        case "max_percentaje_jurisdiction":
        case "max_percentaje_jurisdiction_hospital":
          icon = "feather icon-trending-up mr-2";
          break;
        case "min_percentaje_jurisdiction":
        case "min_percentaje_jurisdiction_hospital":
          icon = "feather icon-trending-down mr-2";
          break;
      }
      return icon;
    },
    titleCard() {
      let title = "";
      switch (this.id) {
        case "general_jurisdiction":
          title = "ABASTO GENERAL EN LA JURISDICCIÓN";
          break;
        case "max_percentaje_jurisdiction":
        case "max_percentaje_jurisdiction_hospital":
          title = "Centro con Mayor Abasto";
          break;
        case "min_percentaje_jurisdiction":
        case "min_percentaje_jurisdiction_hospital":
          title = "Centro con Menor Abasto";
          break;
      }
      return title;
    },
    refreshSupplyChart() {
      this.createdChart(false);
    },
    createdChart(val) {
      let percentage = 0;
      if (!val) {
        this.chart.destroy();
        percentage =
          this.id == "general_jurisdiction"
            ? this.modelJurisdiction.generalAverage.average
            : this.id == "max_percentaje_jurisdiction"
            ? this.modelJurisdiction.maxPercentage.percentageSupplier
            : this.modelJurisdiction.minPercentage.percentageSupplier;
      } else {
        percentage =
          this.id == "general_jurisdiction" ? this.percentage : this.percentage;
      }
      this.doughut = {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: percentage >= 100 ? [100] : [percentage, 100 - percentage],
              backgroundColor: UtilFront.colorPercentaje(percentage),
            },
          ],
        },
        plugins: [
          {
            id: "customPlugin",
            beforeDraw: function(chart) {
              var width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;

              ctx.restore();
              var fontSize = (height / 100).toFixed(2);
              ctx.font = fontSize + "em Roboto-Regular";
              ctx.textBaseline = "middle";

              var text = percentage >= 100 ? 100 + "%" : percentage + "%",
                textX = Math.round(
                  (width - ctx.measureText(text).width) / 1.95
                ),
                textY = height / 1.9;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ],
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          responsive: true,
          cutoutPercentage: 55,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      };
      if (!val) {
        const ctxDougHut = document.getElementById(this.id);
        this.chart = new Chart(ctxDougHut, this.doughut);
      }
    },
  },
  created() {
    this.createdChart(true);
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
<style scoped></style>
