var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 col-xl-12"},[(_vm.listBranchOffice.length > 0 || _vm.listBranchOffice == undefined)?_c('div',{staticClass:"card br"},[_c('div',{staticClass:"card-header container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right pb-0"},[_c('button',{staticClass:"btn btn-outline-success br mr-2 mb-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.excelByHealthCenter()}}},[_c('i',{staticClass:"feather icon-download mr-2"}),_vm._v(" Centros de salud (xlsx) ")]),_c('button',{staticClass:"btn btn-outline-success br mr-2 mb-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.excelByHospital()}}},[_c('i',{staticClass:"feather icon-download mr-2 "}),_vm._v(" Hospitales (xlsx) ")])])])]),_c('div',{staticClass:"card-body pt-0 pl-0 pr-0"},[_c('v-data-table',{staticClass:"p-4",attrs:{"headers":_vm.headers,"items":_vm.listBranchOffice,"search":_vm.search,"fixed-header":"","height":"auto","footer-props":{
            'items-per-page-text': _vm.$t('table.item_table'),
            'items-per-page-all-text': _vm.$t('table.all'),
            'items-per-page-options': [-1],
          },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":"","header-props":{ sortByText: 'Ordenar por' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4 col-xl-4 pb-0 pt-0"},[_c('v-text-field',{staticClass:"br",attrs:{"outlined":"","dense":"","append-icon":"feather icon-search","label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-3 col-xl-3  pb-0 pt-0"},[_c('v-select',{staticClass:"br",attrs:{"label":"Tipo de Centro","dense":"","clear-icon":"feather icon-x-circle","items":_vm.listFilterType,"clearable":"","append-icon":"feather icon-chevron-down","outlined":""},model:{value:(_vm.typeCenter),callback:function ($$v) {_vm.typeCenter=$$v},expression:"typeCenter"}})],1),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-3 col-xl-3  pb-0 pt-0"},[_c('v-select',{staticClass:"br",attrs:{"label":"Zona","dense":"","clear-icon":"feather icon-x-circle","items":_vm.type,"clearable":"","append-icon":"feather icon-chevron-down","outlined":""},model:{value:(_vm.typeBranch),callback:function ($$v) {_vm.typeBranch=$$v},expression:"typeBranch"}})],1)])]},proxy:true},{key:"item.percentageSupplier",fn:function(ref){
          var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-progress-linear',{staticClass:"br",attrs:{"color":_vm.utilFront.colorPercentajeTable(item.percentageSupplier),"height":"18","value":item.percentageSupplier,"rounded":"","dark":""}},[(item.percentageSupplier == 0)?_c('strong',[_vm._v("-")]):(item.percentageSupplier > 100)?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil(item.percentageSupplier))+"%")])]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',[(item.percentageSupplier == 0)?_c('strong',[_vm._v("-")]):(item.percentageSupplier > 100)?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil(item.percentageSupplier))+"%")])]):_vm._e()]}},{key:"item.percentageKey",fn:function(ref){
          var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-progress-linear',{staticClass:"br",attrs:{"color":_vm.utilFront.colorPercentajeTable(item.percentageKey),"height":"18","value":item.percentageKey,"rounded":"","dark":""}},[(item.percentageKey == 0)?_c('strong',[_vm._v("-")]):(item.percentageKey > 100)?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil(item.percentageKey))+"%")])]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',[(item.percentageKey == 0)?_c('strong',[_vm._v("-")]):(item.percentageKey > 100)?_c('strong',[_vm._v("100%")]):_c('strong',[_vm._v(_vm._s(Math.ceil(item.percentageKey))+"%")])]):_vm._e()]}},{key:"item.tag",fn:function(ref){
          var item = ref.item;
return [(item.zone == '1')?_c('i',[_c('img',{staticClass:"img-fluid filter-green",attrs:{"height":"20","width":"20","src":require("@/other/assets/img/home.svg"),"alt":"maps"}})]):_vm._e(),(item.zone == '2')?_c('i',[_c('img',{staticClass:"img-fluid filter-gray",attrs:{"height":"20","width":"20","src":require("@/other/assets/img/city.svg"),"alt":"maps"}})]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.onChangeTableItem(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v("Detalle")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-green",on:{"click":function($event){return _vm.downloadExcel(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-file mr-1"})])]}}],null,true)},[_c('span',[_vm._v("Descargar Existencia")])])]}}],null,true)})],1)]):_c('div',[_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-0"},[_c('h2',{staticClass:"ml-1"},[_vm._v("CENTROS DE SALUD Y HOSPITALES")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header text-center"},[_c('h2',[_vm._v("SIN SUCURSALES ASIGNADAS")])])])}]

export { render, staticRenderFns }