<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div
        v-if="listBranchOffice.length > 0 || listBranchOffice == undefined"
        class="card br"
      >
        <div class="card-header container-fluid">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-0">
              <h2 class="ml-1">CENTROS DE SALUD Y HOSPITALES</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right pb-0">
              <button
                type="button"
                @click="excelByHealthCenter()"
                class="btn btn-outline-success br mr-2 mb-4"
              >
                <i class="feather icon-download mr-2"></i> Centros de salud
                (xlsx)
              </button>
              <button
                type="button"
                @click="excelByHospital()"
                class="btn btn-outline-success br mr-2 mb-4"
              >
                <i class="feather icon-download mr-2 "></i> Hospitales (xlsx)
              </button>
            </div>
          </div>
        </div>
        <div class="card-body pt-0 pl-0 pr-0">
          <v-data-table
            :headers="headers"
            :items="listBranchOffice"
            :search="search"
            class="p-4"
            fixed-header
            height="auto"
            :footer-props="{
              'items-per-page-text': $t('table.item_table'),
              'items-per-page-all-text': $t('table.all'),
              'items-per-page-options': [-1],
            }"
            :loading-text="$t('table.loading')"
            :no-data-text="$t('table.no_results_available')"
            :no-results-text="$t('table.no_results_search')"
            dense
            :header-props="{ sortByText: 'Ordenar por' }"
          >
            <template v-slot:top>
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 pb-0 pt-0">
                  <v-text-field
                    outlined
                    dense
                    class="br"
                    append-icon="feather icon-search"
                    v-model="search"
                    :label="$t('table.search')"
                  ></v-text-field>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3  pb-0 pt-0">
                  <v-select
                    class="br"
                    label="Tipo de Centro"
                    dense
                    clear-icon="feather icon-x-circle"
                    :items="listFilterType"
                    clearable
                    append-icon="feather icon-chevron-down"
                    v-model="typeCenter"
                    outlined
                  ></v-select>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3  pb-0 pt-0">
                  <v-select
                    class="br"
                    label="Zona"
                    dense
                    clear-icon="feather icon-x-circle"
                    :items="type"
                    clearable
                    append-icon="feather icon-chevron-down"
                    v-model="typeBranch"
                    outlined
                  ></v-select>
                </div>
              </div>
            </template>

            <template v-slot:[`item.percentageSupplier`]="{ item }">
              <v-progress-linear
                v-if="!$vuetify.breakpoint.xsOnly"
                v-bind:color="
                  utilFront.colorPercentajeTable(item.percentageSupplier)
                "
                height="18"
                class="br"
                :value="item.percentageSupplier"
                rounded
                dark
              >
                <strong v-if="item.percentageSupplier == 0">-</strong>
                <strong v-else-if="item.percentageSupplier > 100">100%</strong>
                <strong v-else
                  >{{ Math.ceil(item.percentageSupplier) }}%</strong
                >
              </v-progress-linear>
              <div v-if="$vuetify.breakpoint.xsOnly">
                <strong v-if="item.percentageSupplier == 0">-</strong>
                <strong v-else-if="item.percentageSupplier > 100">100%</strong>
                <strong v-else
                  >{{ Math.ceil(item.percentageSupplier) }}%</strong
                >
              </div>
            </template>

            <template v-slot:[`item.percentageKey`]="{ item }">
              <v-progress-linear
                v-if="!$vuetify.breakpoint.xsOnly"
                v-bind:color="
                  utilFront.colorPercentajeTable(item.percentageKey)
                "
                height="18"
                :value="item.percentageKey"
                rounded
                dark
                class="br"
              >
                <strong v-if="item.percentageKey == 0">-</strong>
                <strong v-else-if="item.percentageKey > 100">100%</strong>
                <strong v-else>{{ Math.ceil(item.percentageKey) }}%</strong>
              </v-progress-linear>
              <div v-if="$vuetify.breakpoint.xsOnly">
                <strong v-if="item.percentageKey == 0">-</strong>
                <strong v-else-if="item.percentageKey > 100">100%</strong>
                <strong v-else>{{ Math.ceil(item.percentageKey) }}%</strong>
              </div>
            </template>

            <template v-slot:[`item.tag`]="{ item }">
              <i v-if="item.zone == '1'">
                <img
                  height="20"
                  width="20"
                  src="@/other/assets/img/home.svg"
                  class="img-fluid filter-green"
                  alt="maps"
              /></i>

              <i v-if="item.zone == '2'">
                <img
                  height="20"
                  width="20"
                  src="@/other/assets/img/city.svg"
                  class="img-fluid filter-gray"
                  alt="maps"
              /></i>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="hand mr-2"
                    @click="onChangeTableItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="feather icon-eye"></i>
                  </span>
                </template>
                <span>Detalle</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="hand text-fmedical-green"
                    @click="downloadExcel(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="feather icon-file mr-1"></i>
                  </span>
                </template>
                <span>Descargar Existencia</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </div>
      <div v-else>
        <div class="card">
          <div class="card-header text-center">
            <h2>SIN SUCURSALES ASIGNADAS</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import { UtilFront } from "@/other/utils";
import { ExcelRequest } from "@/core/request";

import { DashboardRequest } from "@/other/core";
import moment from "moment";
/* Vuex */
import { mapState } from "vuex";
export default {
  props: {
    headers: {},
    dataList: [],
    isExcel: true,
    isDetail: true,
    onChangeTable: Function,
  },
  data() {
    return {
      search: "",
      utilFront: UtilFront,
      typeBranch: null,
      typeCenter: null,
      type: [
        {
          text: "RURAL",
          value: "1",
        },
        {
          text: "URBANO",
          value: "2",
        },
      ],
    };
  },
  computed: {
    // Extración del state
    ...mapState("global", {
      idDistributionBoard: "idDistributionBoard",
    }),
    ...mapState("jurisdiction", {
      modelJurisdiction: "modelJurisdiction",
    }),
    listFilterType() {
      let dataFilter = [];
      this.dataList.map((data) => {
        let listStatus = {
          value: data.typeBranch,
          text: data.typeBranchName.toUpperCase(),
        };
        dataFilter.push(listStatus);
      });
      let hash = {};
      dataFilter = dataFilter.filter((data) =>
        hash[data.value] ? false : (hash[data.value] = true)
      );
      return dataFilter;
    },
    listBranchOffice() {
      let list = [];
      if (this.typeBranch || this.typeCenter) {
        list = this.dataList.filter((data) => {
          return this.typeBranch && this.typeCenter
            ? data.zone == this.typeBranch && data.typeBranch == this.typeCenter
            : this.typeCenter
            ? data.typeBranch == this.typeCenter
            : data.zone == this.typeBranch;
        });
        return list;
      } else {
        return this.dataList;
      }
    },
  },
  methods: {
    onChangeTableItem(item) {
      this.onChangeTable(item);
    },
    async excelByHealthCenter() {
      await DashboardRequest.reportDashboardBranchOffice(
        {
          type: 3,
          jurisdiction: this.modelJurisdiction.currentJurisdictionID,
          dateDashboard: moment().format("YYYY-MM-DD"),
          dashboardType: 1,
          distributionBoard: this.idDistributionBoard,
        },
        { name: "porcentajeCentrosDeSalud.xlsx" }
      );
      this.$toast.success("Reporte generado (porcentajeCentrosDeSalud.xlsx)");
    },
    async excelByHospital() {
      await DashboardRequest.reportDashboardBranchOffice(
        {
          type: 4,
          jurisdiction: this.modelJurisdiction.currentJurisdictionID,
          dateDashboard: moment().format("YYYY-MM-DD"),
          dashboardType: 1,
          distributionBoard: this.idDistributionBoard,
        },
        { name: "porcentajeHospitales.xlsx" }
      );
      this.$toast.success("Reporte generado (porcentajeHospitales.xlsx)");
    },
    downloadExcel(item) {
      ExcelRequest.reportExpiredStock({
        branchOfficeId: item.idBranchOffice,
        allBranchOfffice: false,
        userId: this.$UtilFront.getDataUser().idUser,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            ExcelRequest.downloadBase64(data, "inventario_existente.xlsx");
            this.$toast.success("Reporte generado (inventario_existente.xlsx)");
          } else {
            this.$toast.error(message);
          }
        })
        .finally(() => {});
    },
  },
  watch: {
    modelJurisdiction: {
      handler: function(val) {
        this.search = "";
        this.typeBranch = null;
        this.typeCenter = null;
      },
      deep: true,
    },
  },
  components: {
    ButtonTableComponent,
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
<style scoped>
.filter-green {
  filter: invert(56%) sepia(73%) saturate(388%) hue-rotate(100deg)
    brightness(96%) contrast(86%);
}
.filter-gray {
  filter: invert(60%) sepia(11%) saturate(550%) hue-rotate(171deg)
    brightness(97%) contrast(90%);
}
</style>
