<template>
  <div>
    <div class="row align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <CardComponents class="abasto_abasto" />
      </div>
    </div>
    <div class="row abasto_abasto_secod">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 pb-0">
        <JaliscoDoughutCharComponent
          id="general"
          :percentaje="modelDetailsChart.generalAverage"
          :modelDetailsChart="modelDetailsChart"
          ref="porcentaje_general"
        />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 pb-0">
        <JaliscoDoughutCharComponent
          id="max_percentaje"
          :percentaje="modelDetailsChart.maxPercentaje"
          :modelDetailsChart="modelDetailsChart"
          ref="porcentaje_max"
        />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 pb-0">
        <JaliscoDoughutCharComponent
          id="min_percentaje"
          :percentaje="modelDetailsChart.minPercentaje"
          :modelDetailsChart="modelDetailsChart"
          ref="porcentaje_min"
        />
      </div>
    </div>
    <div class="row map_map">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
        <div class="card">
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
              <JaliscoMapComponent @mapClick="onMapClick"  ref="jalisco_map" />
            </div>
            <v-divider class="mt-3 mb-3" inset vertical></v-divider>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8  map_map_second">
              <JaliscoChartBarComponent
                @mapClick="onMapClick"
                :modelDetailsChart="modelDetailsChart"
                ref="jalisco_bar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-header container-fluid">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8 col-xl-10 pt-6 pb-0">
                <h2 class="ml-1">
                  DESGLOSE DE RECETAS SURTIDAS Y PIEZAS DISPENSADAS
                </h2>
              </div>
              <div
                class="col-sm-12 col-md-12 col-lg-4 col-xl-2 text-right pt-6 pb-0"
              >
                <button
                  type="button"
                  @click="charjs = !charjs"
                  class="btn btn-outline-success br mr-2 mb-4"
                >
                  <i
                    v-bind:class="
                      !charjs
                        ? 'feather icon-bar-chart-2 mr-2'
                        : 'feather icon-layout mr-2'
                    "
                  ></i>
                  {{ !charjs ? "Grafica" : "Tabla" }}
                </button>
              </div>
            </div>
          </div>

          <div class="card-body pt-0 p-0">
            <TableComponent v-show="!charjs" />
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                <ChartTableComponentPrecription v-show="charjs" />
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
                <ChartTableComponentDispensing v-show="charjs" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponents from "@/other/components/jalisco/supply-general/CardComponents";
import JaliscoMapComponent from "@/other/components/jalisco/supply-general/JaliscoMapComponent";
import JaliscoChartBarComponent from "@/other/components/jalisco/supply-general/JaliscoChartBarComponent";
import JaliscoDoughutCharComponent from "@/other/components/jalisco/supply-general/JaliscoDoughutCharComponent";
import TableComponent from "@/other/components/jalisco/supply-general/TableComponent";
import ChartTableComponentDispensing from "@/other/components/jalisco/supply-general/ChartTableComponentDispensing";
import ChartTableComponentPrecription from "@/other/components/jalisco/supply-general/ChartTableComponentPrecription";
import moment from "moment";
import "intro.js/introjs.css";

/* Vuex */
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      charjs: true,
    };
  },
  computed: {
    // Extración del state
    ...mapState("supply", {
      modelDetailsChart: "modelDetailsChart",
    }),
    ...mapState("global", {
      idDistributionBoard: "idDistributionBoard",
    }),
  },
  methods: {
    ...mapActions("supply", {
      dashboardBranchOficceDispensingTableAction:
        "dashboardBranchOficceDispensingTableAction",
    }),
    ...mapActions("jurisdiction", {
      dashboardDetailJurisdictionAction: "dashboardDetailJurisdictionAction",
      dashboardJurisdictionChartAction: "dashboardJurisdictionChartAction",
      dashboardBranchOfficeByJurisdiction:
        "dashboardBranchOfficeByJurisdiction",
    }),
    ...mapActions("branchOffice", {
      dashboardBranchOficceDetailAction: "dashboardBranchOficceDetailAction",
    }),
    async onMapClick(val) {
      let payloadDetails = {
        dateDashboard: moment().format("YYYY-MM-DD"),
        jurisdictionId: val.idJurisdiction,
      };
      let payloadTables = {
        jurisdictionId: val.idJurisdiction,
        distributionBoardId: this.idDistributionBoard,
        jurisdictionName: val.province.toUpperCase(),
      };
      await this.dashboardDetailJurisdictionAction(payloadDetails);
      await this.dashboardJurisdictionChartAction(payloadTables);
      await this.dashboardBranchOficceDetailAction(null);
    },
  },
  watch: {
    "modelDetailsChart.percentageList": {
      handler: function(val) {
        this.$refs.jalisco_bar.refreshJurisdictionsBar(val);
      },
      deep: true,
    },
    modelDetailsChart: {
      handler: function(val) {
        this.$refs.porcentaje_general.refreshSupplyChart(val);
        this.$refs.porcentaje_min.refreshSupplyChart(val);
        this.$refs.porcentaje_max.refreshSupplyChart(val);
      },
      deep: true,
    },
  },
  created() {
    this.dashboardBranchOficceDispensingTableAction();
  },
  components: {
    CardComponents,
    JaliscoMapComponent,
    JaliscoChartBarComponent,
    JaliscoDoughutCharComponent,
    TableComponent,
    ChartTableComponentDispensing,
    ChartTableComponentPrecription,
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1200px) {
  .theme--light.v-divider {
    border-color: 1px solid rgba(8, 8, 8, 0.116) !important;
  }
}
@media only screen and (max-width: 1200px) {
  .theme--light.v-dividert {
    border-color: 0px solid rgba(8, 8, 8, 0.116) !important;
  }
}
</style>
<style scoped src="@/other/assets/css/app.css"></style>
