<template>
  <div>
    <div class="row align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <BreadCrumBarComponent />
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <CardComponents />
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <ChartBarPercentageComponent
          v-show="
            generalAverage.percentageSupply != 0 &&
              generalAverage.percentageKey != 0
          "
        />
      </div>
      <div
        class="col-sm-12 col-md-12 col-lg-6 col-xl-4"
        v-show="generalAverage.percentageSupply != 0"
      >
        <JaliscoDoughutCharComponent
          id="percentaje_supply"
          ref="percentaje_supply"
          :percentage="generalAverage.percentageSupply"
          title="PORCENTAJE DE ABASTO EN EL CENTRO DE SALUD"
          :generalAverage="generalAverage"
        />
      </div>
      <div
        class="col-sm-12 col-md-12 col-lg-6 col-xl-4"
        v-show="generalAverage.percentageKey != 0"
      >
        <JaliscoDoughutCharComponent
          id="percentaje_key"
          ref="percentaje_key"
          :percentage="generalAverage.percentageKey"
          title="NIVEL DE EXISTENCIA ÓPTIMO"
          :generalAverage="generalAverage"
        />
      </div>
    </div>
    <div
      v-if="
        generalAverage.percentageSupply == 0 &&
          generalAverage.percentageKey == 0
      "
      class="breadcrumbbar m-0 text-center"
    >
      <h2 id="noinfo">
        SUCURSAL SIN PARAMETROS
      </h2>
    </div>
    <div
      v-show="warehauseDetailStockDistributionBoard.length != 0"
      class="row align-items-center"
    >
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <TableComponent
          :headers="headers"
          :dataList="warehauseDetailStockDistributionBoard"
          :onChangeTable="onChangeTable"
          title="Claves Dentro del Cuadro de Distribución"
          :isDetail="true"
        />
      </div>
    </div>
    <div v-show="warehauseDetail.length != 0" class="row align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <TableComponent
          :headers="headers_another"
          :dataList="warehauseDetail"
          :onChangeTable="onChangeTable"
          :isDetail="false"
          title="Claves Fuera del Cuadro de Distribución"
        />
      </div>
    </div>
    <DialogComponent :dialog="dialog" />
  </div>
</template>

<script>
import BreadCrumBarComponent from "@/other/components/jalisco/branchOffice/BreadCrumBarComponent";
import JaliscoDoughutCharComponent from "@/other/components/jalisco/branchOffice/JaliscoDoughutCharComponent";
import ChartBarPercentageComponent from "@/other/components/jalisco/branchOffice/ChartBarPercentageComponent";
import TableComponent from "@/other/components/jalisco/branchOffice/TableComponent";
import DialogComponent from "@/other/components/jalisco/branchOffice/DialogComponent";
import CardComponents from "@/other/components/jalisco/branchOffice/CardComponents";
import { mapActions, mapState } from "vuex";
import {
  BRANCH_OFFICE_DETAIL,
  BRANCH_OFFICE_DETAIL_ANOTHER,
} from "@/other/utils/DataTableHeaders";

export default {
  data() {
    return {
      headers: BRANCH_OFFICE_DETAIL(),
      headers_another: BRANCH_OFFICE_DETAIL_ANOTHER(),
      dialog: {
        dialog: false,
      },
    };
  },
  computed: {
    // Extración del state
    ...mapState("branchOffice", {
      generalAverage: "generalAverage",
      warehauseDetailStockDistributionBoard:
        "warehauseDetailStockDistributionBoard",
      warehauseDetail: "warehauseDetail",
      productCount: "productCount",
      idBranchOffice: "idBranchOffice",
    }),
  },
  methods: {
    ...mapActions("branchOffice", {
      dashboardBranchOficceDispencingAction:
        "dashboardBranchOficceDispencingAction",
    }),
    async onChangeTable(item) {
      let data = {
        idProduct: item.idProduct,
        branchOffice: { idBranchOffice: this.idBranchOffice },
      };
      let payload = {
        data,
        item,
      };
      await this.dashboardBranchOficceDispencingAction(payload);
      this.dialog.dialog = true;
    },
  },
  watch: {
    generalAverage: {
      handler: function(val) {
        this.$refs.percentaje_supply.refreshSupplyChart(val);
        this.$refs.percentaje_key.refreshSupplyChart(val);
      },
      deep: true,
    },
  },
  components: {
    BreadCrumBarComponent,
    JaliscoDoughutCharComponent,
    TableComponent,
    ChartBarPercentageComponent,
    DialogComponent,
    CardComponents,
  },
};
</script>
<style scoped src="@/other/assets/css/app.css"></style>
