export const BRANCH_OFFICE_BY_JURISDICTION = () => {
  return [
    { text: "Zona", value: "tag", width: "7%"},
    { text: "Tipo de Centro", value: "typeBranchName" },
    { text: "Almacén", value: "name" },
    { text: "Clues", value: "branchOfficeKey" },
    { text: "Porcentaje de Abasto", value: "percentageSupplier",  width: "15%", align: "center" },
    { text: "Nivel de Existencia Óptimo", value: "percentageKey" ,  width: "18%", align: "center" },
    { text: "Detalle", value: "actions", sortable: false },
  ];
};


export const BRANCH_OFFICE_DETAIL = () => {
  return [
    { text: "Clave", value: "productKey" },
    /* { text: "Estado", value: "status", align: "center" }, */
    { text: "Descripción", value: "description", width: "38%" },
    { text: "Existencias", value: "quantity", align: "center" },
    { text: "Dispensado", value: "quantityPrescription", align: "center" },
    //{ text: "Claves De Cuadro de Distribución", value: "tag" , width: "10%", align: "center" },
    { text: "Mínimos", value: "cpmMin" , align: "center" },
    { text: "Máximos", value: "cpmMax" , align: "center" },
    { text: "Nivel de Existencia Óptimo", value: "percentage" ,width: "14%", align: "center" },
    { text: "Detalles", value: "actions" , align: "center" },
  ];
};

export const BRANCH_OFFICE_DETAIL_ANOTHER  = () => {
  return [
    { text: "Clave", value: "productKey" },
    { text: "Descripción", value: "description", width: "45%" },
    { text: "Existencias", value: "quantity", align: "center" },
    { text: "Dispensado", value: "quantityPrescription", align: "center" },
    { text: "Detalles", value: "actions" , align: "center" },

  ];
};


export const BRANCH_OFFICE_BY_DISPENCING = () => {
  return [
    { text: "ID receta", value: "idPrescription" , sortable: false},
    /* { text: "Estado", value: "status", align: "center" }, */
   /*  { text: "Tipo de Receta", value: "branchOfficeKey" }, */
    { text: "Beneficiario", value: "medicalRecord", sortable: false },
    { text: "Curp", value: "curp", align: "center" , sortable: false},
    { text: "Fecha de Surtimiento", value: "datePrescription" , align: "center", sortable: false },
    { text: "Porcentaje de Surtimiento", value: "supplyPercentage" , align: "center", sortable: false },
    { text: "PDF", value: "actions", sortable: false, sortable: false },
  ];
};

export const DISPENCING = () => {
  return [
    { text: "Mes", value: "mes" },
    { text: "Año", value: "anio" },
    { text: "No. De Recetas Surtidas", value: "quantityS", align: "center" },
    { text: "No. De Piezas Dispensadas", value: "quantityD" , align: "center" },
  ];
};
