<template>
  <v-dialog content-class="elevation-0" v-model="isLoading" persistent>
    <div class="sk-folding-cube">
      <div class="sk-cube1 sk-cube"></div>
      <div class="sk-cube2 sk-cube"></div>
      <div class="sk-cube4 sk-cube"></div>
      <div class="sk-cube3 sk-cube"></div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    // Extración del state
    ...mapState("global", {
      isLoading: "isLoading",
    }),
  },
};
</script>

<!-- <template>
  <v-dialog content-class="elevation-0" v-model="isLoading" persistent>
    <section>
      <div class="loading loading01">
        <span
          ><img src="@/other/assets/img/loading/completo.png" class="img-fluid" alt="logo"
        /></span>
        
      </div>
    </section>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    // Extración del state
    ...mapState("global", {
      isLoading: "isLoading",
    }),
  },
};
</script>
<style scoped lang="scss" src="@/other/assets/css/loading_scald.scss"></style>;

<style scoped>

@media (min-width: 768px) {
  .img-fluid {
    width: 50%;
    height: 50%;
  }
}

</style> -->