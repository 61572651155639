<template>
  <transition name="fade">
    <div v-if="loadingPage" id="containerbar">
      <div
        id="containerbar"
        v-bind:class="$vuetify.breakpoint.mdAndDown ? 'pb' : ''"
      >
        <LoadingCommon />
        <div v-if="dashboardType != 0">
          <div v-if="dashboardList.length > 1" class="rightbar_two p-0 pb-8">
            <div class="breadcrumbbar">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
                  <h4 class="page-title">{{ $t("dashboard.supplies") }}</h4>
                  <div class="breadcrumb-list">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="/dashboard">{{ $t("dashboard.home") }}</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#"> {{ $t("dashboard.dashboard") }}</a>
                      </li>
                    </ol>
                  </div>
                </div>
                <div
                  class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button"
                >
                  <button
                    type="button"
                    class="btn btn-outline-success button-width mr-2 text-right"
                    v-b-toggle.collapse-1
                  >
                    <i class="fa fa-filter mr-1"></i>
                    {{ $t("dashboard.filter") }}
                  </button>
                </div>
              </div>
              <b-collapse id="collapse-1">
                <div class="row">
                  <div
                    v-bind:class="
                      $vuetify.breakpoint.smAndDown
                        ? 'col-md-12 col-lg-12 col-xs-12 col-sm-12 p-4 pb-2'
                        : 'col-md-12 col-lg-12 col-xs-12 col-sm-12'
                    "
                    v-if="dashboardList.length > 1"
                  >
                    <v-select
                      :value="dashboardType"
                      @change="updatedashboardType"
                      :items="dashboardList"
                      dense
                      hide-details
                      outlined
                      class="br"
                      :label="$t('dashboard.dashboard_type')"
                      data-vv-name="select"
                    ></v-select>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
          <div>
            
            <DashBoardView v-if="dashboardType == 1" />
            <StatisticsView v-if="dashboardType == 2" />
          </div>
        </div>
        <div v-if="dashboardType == 0" class="rightbar_two p-0 pb-8">
          <div class="breadcrumbbar text-center">
            <Error class="pb-3" />
            <h1 id="noinfo">
              Tablero de control sin parámetros.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LoadingCommon from "@/other/common/LoadingCommon";
import DashBoardView from "@/other/views/dashboard/DashBoardView";
import StatisticsView from "@/other/views/dashboard/StatisticsView";
import Error from "@/other/common/Error";
import { UtilFront, PermissionConstants } from "@/core";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      permissions: PermissionConstants,
      loadingPage: false,
    };
  },
  computed: {
    // Extración del state
    ...mapState("global", {
      dashboardList: "dashboardList",
      dashboardType: "dashboardType",
      isLoading: "isLoading"
    }),
  },
  methods: {
    ...mapMutations("global", {
      setTypeDashboard: "TYPE_DASHBOARD_USER",
      clearUser: "USER_NIVEL_ERROR",
    }),
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    ...mapActions({
      getDashboardList: "global/getDashboardList",
    }),
    updatedashboardType(data) {
      this.setTypeDashboard(data);
    },
    async getDashboardListMain() {
      this.clearUser();
      let payload = {
        user: {
          idUser: UtilFront.getDataUser().idUser,
        },
      };
      await this.getDashboardList(payload);
    },
  },
  async created() {
    this.clearUser();
    setTimeout(() => (this.loadingPage = !this.loadingPage), 10);
    this.loadingPage = false;
    this.getDashboardListMain();
  },
  components: {
    LoadingCommon,
    Error,
    StatisticsView,
    DashBoardView,
  },
  watch: {
    isLoading() {
      window.scrollTo({
        top: 0,
        left: 100,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style src="@/other/assets/css/tour.css"></style>

<style scoped src="@/other/assets/css/app.css"></style>
<style src="@/other/assets/css/loading.css"></style>

<style scoped lang="scss">
::v-deep {
  div {
    font-family: "Roboto-Regular" !important;
  }
}
</style>
